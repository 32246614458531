import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from '@angular/router';
import { UserService } from 'app/core/user/user.service';
import { controlAccessPopUp } from 'app/modules/ui-notification/ControlAccessDialog/controlaccess.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


export function LicenseGuardGenerator(routes: string[], minLicence: number): any {
    @Injectable({
        providedIn: 'root',
    })
    class LicenceGuard implements CanActivate, CanActivateChild, CanLoad {
        constructor(private _userService: UserService, private _router: Router, private _matDialog: MatDialog) { }

        canActivate(
            next: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
            console.log("canActivate")
            return this._check(routes.find(r => location.pathname.includes(r))!= null)
        }

        canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
            console.log("canActivateChild")
            return this._check(routes.find(r => location.pathname.includes(r)) != null)
        }

        canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
            console.log("canLoad")
            return this._check(routes.find(r => location.pathname.includes(r)) != null)
        }

        private _check(redirectToCompany: boolean): Observable<boolean> {
            return this._userService.user$.pipe(map(u => {
                
                if (u.accessLevel < minLicence) {
                    var neededAccountLicence: string = "Premium"
                    switch (minLicence) {
                        case 2: neededAccountLicence = "Premium +"; break
                        case 3: neededAccountLicence = "Corporatings viewer"; break
                        case 4: neededAccountLicence = "Corporatings Admin"; break
                        default: break
                    }
                    if (redirectToCompany) {
                        this._router.navigate(['/']).then(
                            (navigated: boolean) => {
                                controlAccessPopUp(
                                    this._matDialog,
                                    neededAccountLicence,
                                    "Subscribe to " + neededAccountLicence
                                );
                            }
                        )
                    }
                    else {
                        controlAccessPopUp(
                            this._matDialog,
                            neededAccountLicence,
                            "Subscribe to " + neededAccountLicence
                        );
                    }
                }
                return u.accessLevel >= minLicence;
            }));
        }
    }

    return LicenceGuard
}



