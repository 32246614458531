import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { QueryEntity, QueryMetric, QueryPeriod } from 'app/layout/common/querycart/querycart.types';
import { map, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class QueryCartService {
    entities$: BehaviorSubject<QueryEntity[]> = new BehaviorSubject<QueryEntity[]>([]);
    metrics$: BehaviorSubject<QueryMetric[]> = new BehaviorSubject<QueryMetric[]>([]);
    periods$: BehaviorSubject<QueryPeriod[]> = new BehaviorSubject<QueryPeriod[]>([]);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all entities
     */
    getAllEntities(): Observable<QueryEntity[]> {
        return new Observable<QueryEntity[]>();
    }

    /**
     * Get all metrics
     */
    getAllMetrics(): Observable<QueryMetric[]> {
        return new Observable<QueryMetric[]>();
    }

    /**
     * Get all periods
     */
    getAllPeriods(): Observable<QueryPeriod[]> {
        return new Observable<QueryPeriod[]>();
    }

    /**
     * Add entity
     *
     * @param entity
     */
    addEntity(entity: QueryEntity): void {
        let current: QueryEntity[] = this.entities$.value;
        if (!current.some(qe => qe.id == entity.id)) {
            this.entities$.next(
                current.concat([entity])
            );
        }
    }

    /**
     * Add metric
     *
     * @param metric
     */
    addMetric(metric: QueryMetric): void {
        let current: QueryMetric[] = this.metrics$.value;
        if (!current.some(qm => qm.id == metric.id)) {
            this.metrics$.next(
                current.concat([metric])
            );
        }
    }

    /**
     * Add period
     *
     * @param period
     */
    addPeriod(period: QueryPeriod): void {
        let current: QueryPeriod[] = this.periods$.value;
        if (!current.some(qm => qm.id == period.id)) {
            this.periods$.next(
                current.concat([period])
            );
        }
    }

    /**
     * Clear everything in the query
     *
     * @param period
     */
    clearAll(): void {
        this.metrics$.next([]);
        this.entities$.next([]);
        this.periods$.next([]);
    }

    /**
     * Delete entity
     *
     * @param id
     */
    deleteEntity(id: number): void {
        this.entities$.next(
            this.entities$.value.filter(qe => qe.id != id)
        );
    }

    /**
     * Delete metric
     *
     * @param id
     */
    deleteMetric(id: number): void {
        this.metrics$.next(
            this.metrics$.value.filter(qe => qe.id != id)
        );
    }

    /**
     * Delete period
     *
     * @param id
     */
    deletePeriod(id: number): void {
        this.periods$.next(
            this.periods$.value.filter(qe => qe.id != id)
        );
    }

    /**
     * Is entity with id in query ?
     *
     * @param id
     */
    entityInQuery(id: number): boolean {
        return this.entities$.value.some(entity => entity.id == id);
    }

    /**
     * Is metric with id in query ?
     *
     * @param id
     */
    metricInQuery(id: number): boolean {
        return this.metrics$.value.some(metric => metric.id == id);
    }

    /**
     * Update metric, matching through id
     *
     * @param metric
     */
    updateMetric(updatedMetric: QueryMetric): void {
        let metrics = this.metrics$.value;
        let metric = metrics.find(metric => metric.id == updatedMetric.id);
        if (metric) {
            metric = updatedMetric;
            this.metrics$.next(metrics);
        }
    }



}
