import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, of, throwError, from, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, map, tap, finalize, first, take } from 'rxjs/operators';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { User } from 'app/core/user/user.types';
import { environment } from 'environments/environment';
import { defer } from 'rxjs';
import { UserCredential } from 'firebase/auth';
import firebase from 'firebase/compat/app';



@Injectable()
export class AuthService {
    private _authenticated: boolean = false;
    readonly minutesBeforeRefresh: number = 5

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _auth: AngularFireAuth
    ) {

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }
    set accessTokenExpirationTime(expirationTime: string) {
        localStorage.setItem('accessTokenExpirationTime', expirationTime);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }
    get accessTokenExpirationTime(): string {
        return localStorage.getItem('accessTokenExpirationTime') ?? '';
    }

    /**
     * Setter & getter for refresh token
     */
    /*set accessExpiration(expiration: number) {
        localStorage.setItem('accessExpiration', expiration.toString());
    }

    get accessExpiration(): number {
        const aE = localStorage.getItem('accessExpiration');
        if (!aE || aE == "") {
            return 0;
        }
        const parsed = parseInt(aE, 10);
        return isNaN(parsed) ? 0 : parsed;
    }*/

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return from(this._auth.sendPasswordResetEmail(email));
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(code: string, password: string): Observable<any> {
        return from(this._auth.verifyPasswordResetCode(code)).pipe(switchMap(v => {
            console.log('----------------')
            console.log(v)
            return from(this._auth.confirmPasswordReset(code, password))
        }))
        //return from(this._auth.confirmPasswordReset(code, password))
        //return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in in client
     *
     * @param credentials
     */
    signInAngularFire(credentials: { email: string; password: string }): Observable<any> {
        console.log("signing in");
        console.log(credentials);
        return from(this._auth.signInWithEmailAndPassword(credentials.email, credentials.password))
            .pipe(
                switchMap(
                    (response: firebase.auth.UserCredential) => {
                        console.log(response)
                        console.log(response.user)
                        console.log(response.additionalUserInfo)
                        console.log("response to sign in");
                        //response.user.emailVerified
                        return of(true);
                    }
                )
            );
    }

    /**
     * Sign in in client with phone
     *
     * @param credentials
     */
    signUpWithPhone(phone: string): Observable<any> {
        console.log("signing up with phone number " + phone);
        return from(this._auth.signInWithPhoneNumber(phone, window.recaptchaVerifier))
            .pipe(
                switchMap(
                    (response: any) => {
                        console.log("phone response");
                        console.log(response);
                        return of(response);
                    }
                )
            );
    }

    /**
     * Sign in to send confirmation email
     *
     * @param credentials
     */
    signInForConfirmation(credentials: { email: string; password: string }, phoneNumber: string): Observable<any> {
        console.log("signing in for confirmation...")
        return this.signInAngularFire(credentials)
            .pipe(
                switchMap(done => this._auth.user),
                switchMap(user => {
                    console.log("signed in");
                    console.log(user);
                    defer(() => user.sendEmailVerification())
                        .subscribe(
                            (response: any) => {
                                console.log('email sent');
                            },
                            (response: any) => {
                                console.log("email not sent");
                            },
                        );
                    return from(user.getIdTokenResult())
                }),
                switchMap(token => {
                    this.accessToken = token.token;
                    this.accessTokenExpirationTime = token.expirationTime;
                    return this._httpClient.post<User>(environment.server + 'auth/post-sign-up?phoneNumber=' + phoneNumber, { headers: new HttpHeaders({ 'Authorization': "Bearer " + token }) })
                }),
                switchMap((response: User) => {
                    let user = response;
                    user.accessLevel = response.accessLevel;
                    this._userService.user = user;
                    this._userService.user$.subscribe(u => console.log(u));
                    this._authenticated = true;
                    return of(response);
                })
            );
    }

    /**
     * Sign in in back-end
     *
     * @param credentials
     */
    signInBackEnd(credentials: { email: string; password: string }): Observable<any> {
        return this.signInAngularFire(credentials)
            .pipe(
                switchMap(done => this._auth.user),
                switchMap(user => {
                    return from(user.getIdTokenResult())
                }),
                switchMap(token => {
                    this.accessToken = token.token
                    this.accessTokenExpirationTime = token.expirationTime
                    return this._httpClient.get<User>(environment.server + 'auth/init', { headers: new HttpHeaders({ 'Authorization': "Bearer " + token }) })
                }),
                switchMap((response: User) => {
                    let user = response;
                    user.accessLevel = response.accessLevel;
                    this._userService.user = user;
                    this._userService.user$.subscribe(u => console.log(u));
                    this._authenticated = true;
                    return of(response);
                })
            );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        return of(this.accessToken)
            .pipe(
                switchMap(token => {
                    return this._httpClient.get<User>(environment.server + 'auth/me', { headers: new HttpHeaders({ 'Authorization': "Bearer " + token }) })
                }),
                switchMap((response: User) => {
                    let user = response;
                    user.accessLevel = response.accessLevel;
                    this._userService.user = user;
                    this._userService.user$.subscribe(u => console.log(u));
                    this._authenticated = true;
                    return of(response);
                })
            );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('accessTokenExpirationTime');

        // Set the authenticated flag to false
        this._authenticated = false;

        //Sign out in angular fire and return success
        return from(this._auth.signOut())
            .pipe(
                switchMap(
                    (response: any) => {
                        return of(true);
                    }
                )
            );
    }

    removeAuthToken() {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('accessTokenExpirationTime');

        // Set the authenticated flag to false
        this._authenticated = false;
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { email: string; password: string; }): Observable<any> {
        return from(this._auth.createUserWithEmailAndPassword(user.email, user.password))
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        /*if (AuthUtils.isTokenExpired(this.accessExpiration)) {
            return of(false);
        }*/

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken().pipe(catchError(res => {
            const httpErr = res as HttpErrorResponse
            if (res.status == 403 && httpErr?.error == "Email not verified") {
                console.log(res)
            }
            return of(false)
        }));
    }

    reSendEmailConfirmation() {
        if (this._auth?.user) {
            this._auth.user.subscribe(u => {
                u.sendEmailVerification()
            })
        }
    }

    verifyEmail(oobCode: string): Observable<void> {
        return from(this._auth.applyActionCode(oobCode)).pipe(tap(_ => {
            console.log("Email verified")
        }), switchMap(_ => {
            if (this.accessToken) {
                return this._auth.user
            }
            else {
                return of(null)
            }
        }),
            switchMap((user: firebase.User) => {
                if (user) {
                    return from(user.getIdTokenResult(true))
                }
                return of(null)
            }),
            map((token: firebase.auth.IdTokenResult) => {
                if (token) {
                    this.accessToken = token.token;
                    this.accessTokenExpirationTime = token.expirationTime;
                }
            }),
            catchError(e => {
                throw e
            }))
    }

    /**
     * tries to refresh the current token if there is less than {minutesBeforeRefresh} minutes
     * @returns true iff refreshed, 
     */
    private _refreshToken(): Observable<boolean> {
        if (this.accessTokenExpirationTime) {
            const diff = new Date(this.accessTokenExpirationTime).valueOf() - new Date().valueOf()
            if (diff < 0) {
                this.signOut();

                // Reload the app
                location.reload();

                return of(false)
            }
            else if (diff / 60000 > this.minutesBeforeRefresh) {
                return of(false)
            }
        }
        console.log("..................Refreshing Token..................")
        if (this._auth.user) {
            return this._auth.user.pipe(
                switchMap(
                    (user: firebase.User) => {
                        if (user) {
                            return from(user.getIdTokenResult(true))
                        }
                        return of(null)
                    }
                ),
                switchMap(
                    (token: firebase.auth.IdTokenResult) => {
                        if (token) {
                            this.accessToken = token.token;
                            this.accessTokenExpirationTime = token.expirationTime;
                        }
                        return of(token != null)
                    }
                ),
            )
        }
        return of(false)
    }



    // permet d'attendre enn cas de concurrence car l'impact de getIdTokenResult peut être grand
    refreshTokenWaiter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null) 
    usingRefreshToken: boolean = false 
    refreshToken(): Observable<boolean> {
        if (this.usingRefreshToken) {
            return this.refreshTokenWaiter.pipe(first(v => v != null))
        }
        this.refreshTokenWaiter.next(null) //BehaviorSubject relance le dernier élément.. nous devons la changer
        this.usingRefreshToken = true
        return this._refreshToken()
            .pipe(
                take(1),
                tap(r => {
                    this.refreshTokenWaiter.next(r)
                }),
                finalize(() => {
                    this.usingRefreshToken = false
                    this.refreshTokenWaiter.next(null) //Faire attendre les nouveaux concurrents
                })
            )
    }

}
