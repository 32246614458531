import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, finalize, first, map, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Sector } from './sector.types';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class SectorService {
    private _sectors: BehaviorSubject<Sector[]> = new BehaviorSubject<Sector[]>(null);
    private _computing: boolean = false;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */

    get sectors$(): Observable<Sector[]> {
        return this._sectors.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    getSectors(): Observable<Sector[]> {

        if (this._computing) {
            return this._sectors.pipe(filter(v => v != null), first())
        }
        if (this._sectors.value == null) {
            this._computing = true
            return this._httpClient
                .get<Sector[]>(environment.server + 'api/issuer/sectors')
                .pipe(
                    finalize(() => this._computing = false),
                    tap((sectors) => {
                        console.log("Response from: " + environment.server + 'api/issuer/sectors');
                        sectors.sort((a, b) => a.sectorName.localeCompare(b.sectorName))
                        this._sectors.next(sectors);
                        
                    })
                );

        }
        else {
            return of(this._sectors.value)
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class SectorResolver implements Resolve<Sector[]>
{
    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _sectorService: SectorService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        console.log("resolving sectors");
        return this._sectorService.getSectors();
    }
}
