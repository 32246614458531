<!-- Query composition toggle -->
<button mat-icon-button (click)="openPanel()" #querySectionsOrigin>
    <ng-container *ngIf="unreadCount > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span class="flex items-center justify-center flex-shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-indigo-600 text-indigo-50 text-xs font-medium">
                {{unreadCount}}
            </span>
        </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:database'"></mat-icon>
</button>

<!-- Query composition panel -->
<ng-template #querySectionsPanel>

    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex flex-shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button mat-icon-button (click)="closePanel()">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">Query parameters</div>
            <div class="ml-auto">
                <button mat-icon-button [disabled]="unreadCount === 0">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:database'"></mat-icon>
                </button>
            </div>
        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-160 divide-y-2 overflow-y-auto bg-card">
            <!-- Query entities -->
            <div>
                <div class="flex group bg-gray-200 hover:bg-gray-300 dark:hover:bg-black dark:hover:bg-opacity-5">
                    <div class="flex flex-row m-4">
                        <mat-icon class="icon-size-5 text-current text-gray-500" svgIcon="heroicons_outline:office-building"></mat-icon>
                        <div class="mx-2">Entities</div>
                    </div>
                </div>
                <div class="divide-y">
                    <ng-container *ngFor="let entity of queryEntities; trackBy: trackByFn">
                        <div class="">
                            <div class="ml-8 m-2">
                                {{entity.name}}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!queryEntities || !queryEntities.length">
                        <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-4 px-8">
                            <div class="text-2xl font-semibold tracking-tight">No parameters added yet</div>
                            <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">When you add parameters, you can review them here.</div>
                        </div>
                    </ng-container>
                </div>
                <div class="flex group bg-gray-200 hover:bg-gray-300 dark:hover:bg-black dark:hover:bg-opacity-5">
                    <div class="flex flex-row m-4">
                        <mat-icon class="icon-size-5 text-current text-gray-500" svgIcon="heroicons_outline:variable"></mat-icon>
                        <div class="mx-2">Metrics</div>
                    </div>
                </div>
                <div class="divide-y">
                    <ng-container *ngFor="let metric of queryMetrics; trackBy: trackByFn">
                        <div class="">
                            <div class="ml-8 m-2">
                                {{metric.intoString()}}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!queryMetrics || !queryMetrics.length">
                        <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-4 px-8">
                            <div class="text-2xl font-semibold tracking-tight">No parameters added yet</div>
                            <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">When you add parameters, you can review them here.</div>
                        </div>
                    </ng-container>
                </div>
                <div class="flex group bg-gray-200 hover:bg-gray-300 dark:hover:bg-black dark:hover:bg-opacity-5">
                    <div class="flex flex-row m-4">
                        <mat-icon class="icon-size-5 text-current text-gray-500" svgIcon="heroicons_outline:calendar"></mat-icon>
                        <div class="mx-2">Periods</div>
                    </div>
                </div>
                <div class="divide-y">
                    <ng-container *ngFor="let period of queryPeriods; trackBy: trackByFn">
                        <div class="">
                            <div class="ml-8 m-2">
                                {{period.label}}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!queryPeriods || !queryPeriods.length">
                        <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-4 px-8">
                            <div class="text-2xl font-semibold tracking-tight">No parameters added yet</div>
                            <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">When you add parameters, you can review them here.</div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-template>