import { MatSnackBar } from "@angular/material/snack-bar";


export function snackAlert(_snackBar: MatSnackBar, message: string, error = false, duration=4000) {
    return _snackBar.open(message, "x", {
        duration: duration,
        panelClass: [error ? 'bg-red-500' : 'bg-green-500', 'text-white', 'font-semibold']
    });
}

export function snackAlertWarning(_snackBar: MatSnackBar, message: string, duration = 4000) {
    return _snackBar.open(message, "x", {
        duration: duration,
        panelClass: ['bg-warning-300', 'text-black', 'font-semibold']
    });
}
