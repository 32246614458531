import { Calculation, Valuation } from "../../core/model/model.types";


export const valuationData: Valuation[] = [
    {
        title: "Benchmark title",
        comment: `In some businesses, Goodwill can represent an important part of assets.
During economic downturn or because of an evolving accounting pratices.
In some businesses, Goodwill caqsdqsdsqdn represent an important part of assets.
During economic downturn or because of an evolving accounting pratices.
In some businesses, Goodwill can represent an important part of assets.
During economic downturn or because of an evolving accounting pratices.
In some businesses, Goodwill can represent an important part of assets.
During economic downturn or because of an evolving accounting pratices.
During economic downturn or because of an evolving accounting pratices. `,
        date: new Date("09.06.2022")
    },

    {
        title: "Benchmark long long long long long long title",
        comment: `Short comment`,
        date: new Date()
    },

    {
        title: "Benchmark long long long long title",
        comment: `In some businesses, Goodwill can represent an important part of assets.
During economic downturn or because of an evolving accounting pratices.
In some businesses, Goodwill caqsdqsdsqdn represent an important part of assets.`,
        date: new Date("09-06-2021")
    },

    {
        title: "Short title",
        comment: `In some businesses, Goodwill can represent an important part of assets.
During economic downturn or because of an evolving accounting pratices.
In some businesses, Goodwill caqsdqsdsqdn represent an important part of assets.
During economic downturn or because of an evolving accounting pratices.`,
        date: new Date("09-01-2020")
    },

    {
        title: "Title",
        comment: `Comment`,
        date: new Date("01-01-2002")
    },

    {
        title: "Short title",
        comment: `Short com`,
        date: new Date("09-01-2030")
    },

];


export const calculationData: Calculation[] = [];
