import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject, of } from 'rxjs';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    private _notifications: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
    get notifications$(): Observable<Notification[]> {
        return this._notifications.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all notifications
     */
    getAll(): Observable<Notification[]> {

        //return of([]);
        if (this._notifications.value == null)
            return this._httpClient.get<Notification[]>(environment.server + 'api/notification/all').pipe(
                tap((notifications) => {
                    notifications = notifications.sort((a, b) => new Date(a.time) <= new Date(b.time) ? 1 : -1)
                    notifications.forEach(n => {
                        n.time = new Date(n.time)
                        n.time.setMinutes(n.time.getMinutes() - n.time.getTimezoneOffset())
                    })
                    this._notifications.next(notifications);
                })
            );
        return of(this._notifications.value)
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => {
                if (notifications.length == 0) return of(true)
                return this._httpClient.post<boolean>(environment.server + 'api/notification?date=' + notifications[0].time.toISOString(), null).pipe(
                    map((isUpdated: boolean) => {

                        // Go through all notifications and set them as read
                        notifications.forEach((notification, index) => {
                            notifications[index].read = true;
                        });

                        // Update the notifications
                        this._notifications.next(notifications);

                        // Return the updated status
                        return isUpdated;
                    }))
            })
        );
    }



    markReadFromNotification(notification: Notification): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => {
                if (notifications.length == 0) return of(true)
                var refDate = new Date(notification.time)
                console.log(refDate)
                refDate.setMilliseconds(refDate.getMilliseconds() + (notification.read ? - 1 : 1))
                console.log(notification.read)
                notification.read = !notification.read
                console.log(refDate)
                console.log(notification.time)
                console.log(refDate)
                return this._httpClient.post<boolean>(environment.server + 'api/notification?date=' + refDate.toISOString(), null).pipe(
                    map((isUpdated: boolean) => {

                        if (isUpdated) {
                            console.log(notifications)
                            console.log(notification)
                            // Go through all notifications and set those with a lower date as read
                            notifications.forEach((n, index) => {
                                notifications[index].read = n.time <= refDate;
                            });
                            
                            console.log(notifications)



                            // Update the notifications
                            this._notifications.next(notifications);
                        }


                        // Return the updated status
                        return isUpdated;
                    }))
            })
        );
    }

}
