import { Component, Inject } from "@angular/core";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";


@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialog {

    //additionalClass ="bg-red-500 text-white"
    constructor(public dialogRef: MatDialogRef<ConfirmDialog>, @Inject(MAT_DIALOG_DATA) public data: { title: string, subtitle: string, confirmText: string, refuseText: string, dontShowAgainButton: boolean }) {

    }

    checked: boolean = false

    onNoClick(): void {
        if (this.data.dontShowAgainButton) {
            this.dialogRef.close({ action: false, dontShowAgain: this.checked });
        }
        else {
            this.dialogRef.close(false);
        }
    }

    onOkClick(): void {
        //console.log(this.checked)
        if (this.data.dontShowAgainButton) {
            this.dialogRef.close({ action: true, dontShowAgain: this.checked });
        }
        else {
            this.dialogRef.close(true);
        }
    }
}

export function openConfirmDialog(dialog: MatDialog, title: string, subtitle: string, confirmText: string, refuseText: string = null, dontShowAgainButton: boolean = false, width: string = "max-content", height: string = "max-content") {
    return dialog.open(ConfirmDialog, {
        disableClose: true,
        autoFocus: false,
        width: width,
        height: height,
        maxHeight: height,
        maxWidth: width,
        backdropClass: ["backdrop-blur"],
        data: { title: title, subtitle: subtitle, confirmText: confirmText, refuseText: refuseText, dontShowAgainButton: dontShowAgainButton }
    })
}


