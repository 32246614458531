import { SelectionModel } from "@angular/cdk/collections";
import { HttpClient } from "@angular/common/http";
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatCheckboxDefaultOptions, MAT_CHECKBOX_DEFAULT_OPTIONS } from "@angular/material/checkbox";
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, combineLatest, Observable, Subject, timer } from "rxjs";
import { first, map, startWith, take, takeUntil } from "rxjs/operators";
import { FuseScrollbarDirective } from "../../../../@fuse/directives/scrollbar";
import { Company } from "../../../core/company/company-raw/company-raw.types";
import { CompanyDTService } from "../../../core/company/company-with-country-and-sector/company-dt.service";
import { CompanyDT } from "../../../core/company/company-with-country-and-sector/company-dt.types";
import { FavoriteCompanyService } from "../../../core/company/entity-group/favorite-company/favorite-company.service";
import { getLogo, testCompanyDTUsingString } from "../../../core/company/utils";
import { NavigationService } from "../../../core/navigation/navigation.service";
import { SectorService } from "../../../core/sector/sector.service";
import { UserService } from "../../../core/user/user.service";
import { openConfirmDialog } from "../../ui-notification/ConfirmDialog/confirm-dialog.component";
import { openErrorMessageDialog } from "../../ui-notification/MessageDialog/message-dialog.component";
import { snackAlert } from "../../ui-notification/SnackBarUtil";
import { openSpinnerDialog } from "../../ui-notification/SpinnerDialog/spinner-dialog.component";

@Component({
    selector: "side-by-side",
    templateUrl: "./favorites.component.html",
    styleUrls: ["./favorites.component.scss"],
    providers: [
        { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'check' } as MatCheckboxDefaultOptions }
    ]
})
export class FavoritesComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(MatPaginator, { static: false }) private paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) private sort: MatSort;
    @ViewChildren(FuseScrollbarDirective) private _fuseScrollbarDirectives: QueryList<FuseScrollbarDirective>


    constructor(private http: HttpClient,
        private _navigationService: NavigationService,
        private router: Router,
        private route: ActivatedRoute,
        private _snackBar: MatSnackBar,
        private companyService: CompanyDTService,
        private favoriteService: FavoriteCompanyService,
        public dialog: MatDialog,
        private changeDetectorRef: ChangeDetectorRef,
        private userService: UserService,
        public sectorService: SectorService
    ) { }


    ngAfterViewInit(): void {
        combineLatest([this.companyService.companies$, this.favoriteService.favorites$]).pipe(takeUntil(this._unsubscribeAll)).subscribe(
            ([allCompanies, favs]) => {
                const localAllCompanies = this.allCompanies = allCompanies
                this.allCompanies.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                favs.sort((a, b) => a.name.localeCompare(b.name))
                this.favorites = favs.reduce(function (filtered, f) {
                    const c = localAllCompanies.find(c => f.id == c.issuerId)
                    if (c) {
                        filtered.push({
                            name: c.name,
                            issuerId: c.issuerId,
                            tags: c.tags.concat([c.sector?.sectorName, c.sector?.industryCode, c.sector?.industryName, c.sector?.sectorCode]),
                            country: c.country,
                            sector: c.sector?.sectorName,
                            addedAt: new Date(f.addedAt)
                        })
                    }
                    return filtered;
                }, []);
                console.log(this.favorites)
                this.dataSource = new MatTableDataSource(this.favorites)
                this.changeDetectorRef.detectChanges();
                this.dataSource.paginator = this.paginator
                this.dataSource.sort = this.sort;
                this.selection.clear();
                this._fuseScrollbarDirectives.forEach((fuseScrollbarDirective) => {
                    fuseScrollbarDirective.update();
                });
            }
        )

    }


    ngOnInit(): void {
        /** Navigation */

        this.userService.user$.subscribe(
            user => {
                this.userName = user.email;
                this._navigationService.ariane = [
                    { title: this.userName, link: "/entities", last: false },
                    { title: "Favorites", link: location.href, last: true },
                ];
            }
        )




    }


    ngOnDestroy(): void {
        /** Unsubscription */
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    private _unsubscribeAll: Subject<any> = new Subject<any>();


    /*** Handlers */
    onViewCompany(com: CompanyDT) {
        this.router.navigateByUrl("/singlecompany/" + com.issuerId)

    }

    onDeleteFromFavorite(com: Company) {
        openConfirmDialog(this.dialog, "Do you really want to remove this company from Favorites?", "You are about to remove company '" + com.name + "' from your favorites, are you OK with it ?", "Remove")
            .afterClosed().subscribe(val => {
                if (val) {
                    this.favoriteService.removeFavCompanies({ id: Number(com.issuerId), name: com.name })
                        .pipe(first())
                        .subscribe(
                            (success: boolean) => {
                                if (success) {
                                    snackAlert(this._snackBar, "Removed sucessfully")
                                }
                                else {
                                    snackAlert(this._snackBar, "An error happened", true)
                                }
                            }
                        )
                }
                else {
                    snackAlert(this._snackBar, "Cancelled")
                }
            }
            )
    }

    onMultipleDeleteFromFavorite() {
        if (this.selection.hasValue()) {
            const toRemove = this.selection.selected;
            if (toRemove.length == 1)
                this.onDeleteFromFavorite(toRemove[0])
            else {
                openConfirmDialog(this.dialog, "Do you really want to remove from Favorites?", "You are about to remove " + toRemove.length + " companies from your favorites, are you OK with it ?", "Remove")
                    .afterClosed().subscribe(async decision => {
                        if (decision) {
                            var b = new BehaviorSubject<string>("Initialization");
                            var r = openSpinnerDialog(this.dialog, b)
                            var failed = 0;
                            var processed = 0;
                            var total = toRemove.length
                            await timer(500).pipe(take(1)).toPromise();
                            for (const c of toRemove) {
                                processed++;
                                b.next("Deleting \"" + c.name + "\" from favorites (" + processed + "/" + total + ")")
                                await timer(500).pipe(take(1)).toPromise();

                                await this.favoriteService.removeFavCompanies({ id: Number(c.issuerId), name: c.name })
                                    .pipe(first())
                                    .subscribe(
                                        async (success: boolean) => {
                                            if (success) {
                                                b.next("Removed sucessfully")
                                            }
                                            else {
                                                failed++
                                            }
                                            await timer(500).pipe(take(1)).toPromise();
                                        },
                                        async error => {
                                            failed++
                                            b.next("Error when removing \"" + c.name + "\" from favorites (" + processed + "/" + total + ")")
                                            await timer(500).pipe(take(1)).toPromise();
                                            console.log(error)
                                        }
                                    )
                                await timer(500).pipe(take(1)).toPromise();
                            }
                            b.complete();
                            if (failed != 0) {
                                openErrorMessageDialog(this.dialog, "An error occured", "" + failed + " on " + total + " deletions failed")
                                //snackAlert(this._snackBar, "" + failed + " on " + total + " reports not found in the database", true)
                            }
                            else {
                                snackAlert(this._snackBar, "All the requested reports have been provided")
                            }
                        }
                        else {
                            snackAlert(this._snackBar, "Cancelled")
                        }
                    })
            }
        }

    }

    onAddFavoriteButton() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = this.allCompanies
        dialogConfig.width = "80%";
        dialogConfig.height = "80%";
        dialogConfig.backdropClass = ["backdrop-blur"]
        this.dialog.open(FavoriteAddDialog, dialogConfig).afterClosed().subscribe(
            (cname) => {
                const c = this.allCompanies.find(c => c.name == cname)
                if (c) {
                    this.favoriteService.addFavCompanies({ id: Number(c.issuerId), name: c.name }).pipe(first()).subscribe(
                        (success) => {
                            if (!success) {
                                snackAlert(this._snackBar, "Already added", true)
                            }
                            else {
                                snackAlert(this._snackBar, "Added as favorite")
                            }
                        },
                        (err) => {
                            console.log(err);
                            snackAlert(this._snackBar, "Failed to add as favorite", true)
                        }
                    )
                }
            }
        );
    }




    /** Table relative data and functions */
    displayedColumns: string[] = ['check-boxes', 'logo', 'company-name', 'company-date', 'company-sector', 'company-action'];
    dataSource: MatTableDataSource<FavoriteCompanyDT> = new MatTableDataSource();


    /** User name */
    userName: string = "";

    /** Favorites */
    favorites: FavoriteCompanyDT[] = []
    selection = new SelectionModel<FavoriteCompanyDT>(true, []);

    /** Companies */
    allCompanies: CompanyDT[] = []




    /** Selection */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    toggleAllRows() {
        if (this.selection.selected.length > 0) {
            this.selection.clear();
            return;
        }
        this.selection.clear();
        this.selection.select(...this.dataSource.data.slice(this.paginator.pageIndex * this.paginator.pageSize, this.paginator.pageIndex * this.paginator.pageSize + this.paginator.pageSize));
    }

    /*** Utils */
    generateDate() { return new Date() }


    onMatSortChange($event) {
        if ($event.direction == "") {
            this.dataSource.data = this.favorites
        }
        else if ($event.active == "company-name") {
            this.dataSource.data = [...this.favorites].sort(function (a, b) { return ($event.direction == "asc" ? 1 : -1) * a.name.localeCompare(b.name) });
        }
        else if ($event.active == "company-date") {
            this.dataSource.data = [...this.favorites].sort(function (a, b) {
                return ($event.direction == "asc" ? 1 : -1) * (a.addedAt <= b.addedAt ? -1 : 1)
            });
        }
        else if ($event.active == "company-sector") {
            this.dataSource.data = [...this.favorites].sort(function (a, b) { return ($event.direction == "asc" ? 1 : -1) * (a.sector ? a.sector : "").localeCompare(b.sector ? b.sector : "") });
        }
    }

    changeStyleTruncate($event: MouseEvent) {
        ($event.target as Element).classList.toggle("truncate")
    }

    companyLogo = getLogo
}

/** Local data type */
interface FavoriteCompanyDT extends Company {
    addedAt: Date;
}

/*interface CompanyDT {
    name: string;
    issuerId: number;
    tags: string[];
    country?: string;
    logo?: string;
    sector: Sector;
    companyNameInReports: string[];
    reports: { id: number; period: number; publicationDate: string; reportAddress: string }[];
    groupNames: string[]
}*/


/** Dialog Component */
@Component({
    template: `
<div class="flex flex-col bg-background-primary rounded-lg shadow-lg w-full h-full p-8 space-y-6">

    <div class="flex w-full justify-between items-center px-4">
        <p class="text-center text-xl font-bold">Add a favorite company</p>
        <button (click)="close()">
            <fa-icon [icon]="['fas', 'xmark']" class="icon-size-4"></fa-icon>
        </button>
    </div>

    <div class="search-bar-secondary bg-background-secondary">
        <input type="text"
            placeholder="Name or LEI"
            [formControl]="form"
            [matAutocomplete]="auto" class="search-input">
        <fa-icon [icon]="['fas', 'magnifying-glass']" class="search-icon-left icon-size-5"></fa-icon>
        <fa-icon [icon]="['fas', 'caret-down']" class="search-icon-right icon-size-6"></fa-icon>
    </div>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)='close($event.option.value)' class="search-bar-dropdown">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name" class="h-12">
            <div class="flex flex-row h-10 w-full space-x-4">
                <img [src]="companyLogo(option.issuerId) | protectedLink | async" class="w-10" alt="Logo image">
                <p class="justify-center leading-10">{{option.name}}</p>
            </div>
        </mat-option>
    </mat-autocomplete>
</div>
`,
    styleUrls: ['../../ui-notification/ConfirmDialog/confirm-dialog.component.scss'],
})



export class FavoriteAddDialog implements OnInit, AfterViewInit {
    form: FormControl;
    filteredOptions: Observable<CompanyDT[]>;
    allCompanies: CompanyDT[]
    constructor(
        public dialogRef: MatDialogRef<FavoriteAddDialog>,
        @Inject(MAT_DIALOG_DATA) data,
    ) {
        this.allCompanies = data
    }

    ngAfterViewInit(): void {

    }


    ngOnInit(): void {
        this.form = new FormControl('');

        this.filteredOptions = this.form.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value)),
        );
    }

    close(cname = null): void {
        this.dialogRef.close(cname);
    }

    private _filter(value: string, count: number = 20): CompanyDT[] {
        const result = []
        var added = 0
        for (const option of this.allCompanies) {
            if (testCompanyDTUsingString(option, value)) {
                result.push(option)
                added++
            }
            if (added >= count) break;
        }
        return result
    }

    companyLogo = getLogo

}
