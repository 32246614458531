import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QueryEntity, QueryMetric, QueryPeriod } from 'app/layout/common/querycart/querycart.types';
import { QueryCartService } from 'app/layout/common/querycart/querycart.service';

@Component({
    selector: 'query-cart',
    templateUrl: './querycart.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'queryCart'
})
export class QueryCartComponent implements OnInit, OnDestroy {
    @ViewChild('querySectionsOrigin') private _querySectionsOrigin: MatButton;
    @ViewChild('querySectionsPanel') private _querySectionsPanel: TemplateRef<any>;

    queryEntities: QueryEntity[];
    queryMetrics: QueryMetric[];
    queryPeriods: QueryPeriod[];
    unreadCount: number = 0;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _queryCartsService: QueryCartService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to queryCart changes
        this._queryCartsService.entities$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((queryEntities: QueryEntity[]) => {

                // Load the queryCarts
                this.queryEntities = queryEntities;

                // Calculate the unread count
                this._calculateUnreadCount();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        this._queryCartsService.metrics$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((queryMetrics: QueryMetric[]) => {

                // Load the queryCarts
                this.queryMetrics = queryMetrics;

                // Calculate the unread count
                this._calculateUnreadCount();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        this._queryCartsService.periods$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((queryPeriods: QueryPeriod[]) => {

                // Load the queryCarts
                this.queryPeriods = queryPeriods;

                // Calculate the unread count
                this._calculateUnreadCount();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the queryCarts panel
     */
    openPanel(): void {
        // Return if the queryCarts panel or its origin is not defined
        if (!this._querySectionsPanel || !this._querySectionsOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._querySectionsPanel, this._viewContainerRef));
    }

    /**
     * Close the queryCarts panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Delete the given entity
     */
    delete(entity: QueryEntity): void {
        // Delete the queryCart
        this._queryCartsService.deleteEntity(entity.id);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._querySectionsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void {
        let count = 0;

        let entitiesCount = 0;
        if (this.queryEntities && this.queryEntities.length) {
            entitiesCount = this.queryEntities.length;
        }

        let metricsCount = 0;
        if (this.queryMetrics && this.queryMetrics.length) {
            metricsCount = this.queryMetrics.length;
        }

        let periodsCount = 0;
        if (this.queryPeriods && this.queryPeriods.length) {
            periodsCount = this.queryPeriods.length;
        }

        if (entitiesCount == 0 && metricsCount == 0 && periodsCount == 0) {
            count = 0;
        }
        else {
            count = Math.max(1, entitiesCount) * Math.max(1, metricsCount) * Math.max(1, periodsCount);
        }

        this.unreadCount = count;
    }
}
