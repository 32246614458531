/* eslint-disable */
import * as moment from 'moment';

export const messages = [
    {
        id         : '832276cc-c5e9-4fcc-8e23-d38e2e267bc9',
        image      : 'assets/images/avatars/male-01.jpg',
        title      : 'Gary Peters',
        description: 'We should talk about that at lunch!',
        time       : moment().subtract(25, 'minutes').toISOString(), // 25 minutes ago
        read       : false
    },
    {
        id         : '608b4479-a3ac-4e26-8675-3609c52aca58',
        image      : 'assets/images/avatars/male-04.jpg',
        title      : 'Leo Gill (Client #8817)',
        description: 'You can download the latest invoices now. Please check and let me know.',
        time       : moment().subtract(50, 'minutes').toISOString(), // 50 minutes ago
        read       : false
    },
    {
        id         : '802935e9-9577-48bc-98d1-308a4872afd7',
        image      : 'assets/images/avatars/male-06.jpg',
        title      : 'William Bell',
        description: 'Did you see this game? We should hang out and give it a shot sometime.',
        time       : moment().subtract(1, 'day').toISOString(), // 1 day ago
        read       : true,
        link       : 'https://www.google.com',
        useRouter  : false
    }
];
