import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IssuerInGroup, UserIssuerGroup } from './entity-group.types';

@Injectable({
    providedIn: 'root'
})

export class EntitiesGroupService {
    // Private
    public _entitiesGroup: BehaviorSubject<UserIssuerGroup[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _navigationService: NavigationService, private router: Router) {
        //console.log("Dans le constructeur de EntitiesGroupService");
        //this.getEntitiesGroup();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for entity
     */
    get entitiesGroup$(): Observable<UserIssuerGroup[]> {
        return this._entitiesGroup.asObservable();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Get all the entities groups
     */
    getEntitiesGroup(): Observable<UserIssuerGroup[]> {
        if (this._entitiesGroup.value == null) {
            return this._httpClient.get<UserIssuerGroup[]>(environment.server + 'api/user/issuer-lists')
                .pipe(map(values => values || []), tap(values => {
                    console.log("Response from: " + environment.server + 'api/user/issuer-lists');
                    console.log(values)
                    this._entitiesGroup.next(values);
                }));
        }
        else {
            return of(this._entitiesGroup.value)
        }
    }

    syncCompanyListInEntityGroup(companies: IssuerInGroup[], groupId: number): Observable<IssuerInGroup[]> {
        var params: string = this.router.createUrlTree(
            [],
            { queryParams: { issuers: companies.map(c => c.id) }, queryParamsHandling: '', relativeTo: null }).toString()
        params = params.substring(params.indexOf("?"))
        const url = environment.server + 'api/user/issuer-list-issuers/' + groupId + (companies.length ? params : '')
        return this._httpClient.put<IssuerInGroup[]>(url, null).pipe(
            tap(
                (response) => {
                    console.log("Response from: " + url);
                    let values = this._entitiesGroup.value;
                    let gIndex = values.findIndex(g => g.id === groupId);
                    if (gIndex >= 0) {
                        values[gIndex].group = response;
                    }
                    this._entitiesGroup.next(values);
                }
            ))
    }

    updateEntityGroupName(groupName: string, groupId: number): Observable<boolean> {
        var params: string = this.router.createUrlTree(
            [],
            { queryParams: { name: groupName }, queryParamsHandling: '', relativeTo: null }).toString()
        params = params.substring(params.indexOf("?"))
        const url = environment.server + 'api/user/issuer-list-name/' + groupId + params
        return this._httpClient.put<boolean>(url, null).pipe(
            tap(
                (response: boolean) => {
                    console.log("Response from: " + url);
                    let values = this._entitiesGroup.value;
                    let gIndex = values.findIndex(g => g.id === groupId);
                    if (gIndex >= 0 && response) {
                        values[gIndex].name = groupName;
                    }
                    this._entitiesGroup.next(values);
                }
            ))
    }

    createEntityGroup(groupName: string, companies: { name: string, id: number }[]): Observable<UserIssuerGroup> {
        var params: string = this.router.createUrlTree(
            [],
            { queryParams: { name: groupName }, queryParamsHandling: '', relativeTo: null }).toString()
        params = params.substring(params.indexOf("?"))
        const url = environment.server + 'api/user/issuer-list' + params
        return this._httpClient.post < {
            groupId: number, createdAt: Date, addDates: { id: number, date: Date }[], userId: string }>(url, companies.map(c => c.id)).pipe(
            map(
                (response: { groupId: number, createdAt: Date, addDates: { id: number, date: Date }[], userId }) => {
                    console.log("Response from: " + url);
                    const result: UserIssuerGroup = {
                        id: response.groupId,
                        name: groupName,
                        createdAt: response.createdAt,
                        group: response.addDates.map((i) => {
                            return {
                                id: i.id,
                                addedAt: i.date,
                                name: companies.find(c => c.id == i.id)?.name
                            }
                        }),
                        userId: response.userId
                    }

                    console.log(result)
                    if (response) {
                        this._entitiesGroup.value.push(result);
                    }
                    this._entitiesGroup.next(this._entitiesGroup.value);
                    return result
                }
            ))
    }

    deleteEntityGroup(groupId: number): Observable<boolean> {
        const url = environment.server + 'api/user/issuer-list/' + groupId
        return this._httpClient.delete<boolean>(url).pipe(
            tap(
                (response) => {
                    console.log("Response from: " + url);
                    if (response) {
                        let gIndex = this._entitiesGroup.value.findIndex(g => g.id === groupId);
                        if (gIndex >= 0) {
                            this._entitiesGroup.value.splice(gIndex, 1);
                        }
                    }
                    this._entitiesGroup.next(this._entitiesGroup.value);
                }
            ))
    }

    addCompanyToEntityGroup(company: IssuerInGroup, groupName: string): Observable<boolean> {
        switch (groupName) {
            case "Favorites":
                {
                    return this._httpClient.post<{ groupId: number, createdAt: Date, userId: string }>(environment.server + 'api/user/favorite/' + company.id, null)
                        .pipe(map(value => {
                            let ret = false;
                            if (value != null && value.userId != null) {
                                ret = this.addElementInGroup(company, groupName, value);
                            }
                            this._entitiesGroup.next(this._entitiesGroup.value);
                            return ret;
                        }));
                }
            case "Most Recent":
                {

                    return this._httpClient.post<{ groupId: number, userId: string }>(environment.server + 'api/user/mostrecent/' + company.id, null)
                        .pipe(map(value => {
                            let ret = false;
                            if (value != null && value.userId != null) {
                                ret = this.addElementInGroup(company, groupName, value, true);
                            }
                            this._entitiesGroup.next(this._entitiesGroup.value);
                            return ret;
                        }));
                }
            default: {
                return of(false);
            }
        }

    }

    deleteCompanyFromEntityGroup(company: IssuerInGroup, groupName: string): Observable<boolean> {
        switch (groupName) {
            case "Favorites":
                {
                    return this._httpClient.delete<{ groupId: number, modified: boolean, userId: string }>(environment.server + 'api/user/favorite/' + company.id)
                        .pipe(map(value => {
                            let values = this._entitiesGroup.value;
                            let ret = false;
                            if (value.groupId != null && value.modified) {
                                let gIndex = values.findIndex(g => g.name === "Favorites");
                                if (gIndex == -1)
                                    values.push({
                                        group: [],
                                        id: value.groupId,
                                        name: "Favorites",
                                        userId: value.userId,
                                    })
                                else {
                                    let gf = values[gIndex];
                                    values.splice(gIndex, 1);
                                    gf.group = gf.group.filter(c => c.id != company.id);
                                    values.push(gf);
                                    ret = true;
                                }
                            }
                            this._entitiesGroup.next(values);
                            return ret;
                        }));
                }
            default:
                return of(false);
        }

    }


    private addElementInGroup(company: IssuerInGroup, groupName: string, value: { groupId: number, createdAt?: Date, userId: string }, insertFirst = false): boolean {
        let values = this._entitiesGroup.value;
        let ret = false;
        if (value.groupId != null) {
            if (value.createdAt) company.addedAt = new Date(value.createdAt)
            let gIndex = values.findIndex(g => g.name === groupName);
            if (gIndex == -1) {
                values.push({
                    group: [company],
                    id: value.groupId,
                    name: groupName,
                    userId: value.userId,
                })
                ret = true;
            }
            else {
                let gf = values[gIndex];
                if (!insertFirst) {
                    if (!gf.group.find(i => i.id == company.id)) {
                        values.splice(gIndex, 1);
                        gf.group.push(company);
                        values.push(gf);
                        ret = true;
                    }
                    else {
                        ret = false;
                    }
                }
                else {
                    const cidx = gf.group.findIndex(i => i.id == company.id)
                    values.splice(gIndex, 1);
                    if (cidx != -1) {
                        gf.group.splice(cidx, 1);
                    }
                    gf.group = [company].concat(gf.group)
                    values.push(gf);
                    ret = true;
                }

            }
        }
        return ret
    }
}  
