<div class="flex flex-col flex-1">
    <div class="flex flex-row">
        <div class="flex flex-row  mt-22 w-full h-full p-4">
            <div class="flex flex-col">
                <div class="flex flex-row w-full mb-5">
                    <img
                    src="assets/images/logo/logo.svg"
                    class="w-14 h-14"
                    alt="Logo image">
                </div>
                <div class="text-6xl font-bold">
                    Subscribe to a <span class="text-red-500">{{data.account}}</span> account to unlock this feature
                </div>
    
                <div mat-dialog-actions class="flex flex-row w-full  mt-5">
                    
                    <button class="bg-red-500 rounded-full hover:shadow-xl px-3 py-2 text-white font-semibold text-base flex flex-row h-10 mx-1" (click)="onOkClick()">
                            <div style="line-height:1.71" class="ml-2">{{ data.confirmText }}</div>
                            <div class="flex flex-col h-full">
                                <div class="flex-1"></div>
                                <mat-icon [svgIcon]="'heroicons_outline:chevron-right'" class="h-4 w-4 min-w-4 min-h-4 text-white"></mat-icon>
                                <div class="flex-1"></div>
                            </div>
                     </button>
                </div>
            </div>
        </div>
        <div class="w-160 h-128 bg-corpo-darkgreen">
            <div class="mt-34 ml-8">
                <p class="text-2xl font-bold text-white">Welcome to Lens</p>
                <div>
                    <img src="./assets/images/imageForPopUp.png" alt="welcomeLens">
                </div>
            </div>
        </div>
    </div>
</div>