import { AuthMockApi } from 'app/mock-api/common/auth/api';
import { IconsMockApi } from 'app/mock-api/ui/icons/api';
import { MessagesMockApi } from 'app/mock-api/common/messages/api';
import { NavigationMockApi } from 'app/mock-api/common/navigation/api';
import { NotificationsMockApi } from 'app/mock-api/common/notifications/api';
import { SearchMockApi } from 'app/mock-api/common/search/api';
import { ShortcutsMockApi } from 'app/mock-api/common/shortcuts/api';
import { UserMockApi } from 'app/mock-api/common/user/api';

import { CollectedMockApi } from 'app/mock-api/collected/api';
import { QueryCartMockApi } from 'app/mock-api/common/querycart/api';
import { ReportsMockApi } from 'app/mock-api/reports/api';
import { MetricsMockApi } from 'app/mock-api/metrics/api';
import { PpaMockApi } from 'app/mock-api/ppa/api';
import { BenchmarkMockApi } from './benchmark/api';
import { ModelMockApi } from './model/api';

export const mockApiServices = [
    AuthMockApi,
    CollectedMockApi,
    ReportsMockApi,
    IconsMockApi,
    MessagesMockApi,
    NavigationMockApi,
    NotificationsMockApi,
    QueryCartMockApi,
    SearchMockApi,
    ShortcutsMockApi,
    PpaMockApi,
    BenchmarkMockApi,
    ModelMockApi
];
