
import { Observable, from, of } from "rxjs";
import { Company } from "./company-raw/company-raw.types";
import { CompanyDT } from "./company-with-country-and-sector/company-dt.types";
import { utf8ToEnglishNormalize } from "./utf8-map";
import { environment } from 'environments/environment';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule, Pipe, PipeTransform } from "@angular/core";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { DomSanitizer } from "@angular/platform-browser";
import { AuthService } from "../auth/auth.service";
import { AuthInterceptor } from "../auth/auth.interceptor";

export function testCompanyDTUsingString(company: CompanyDT, query: string, processedQuery = false) {
    if (!company) return false
    const filterValue = processedQuery ? query : utf8ToEnglishNormalize(query.trim().toLowerCase().replace(' ', '').normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
    //console.log(company.country)
    const result =
        utf8ToEnglishNormalize(company.name.toLowerCase().replace(' ', ''))?.includes(filterValue)
        || company.country.find(c => utf8ToEnglishNormalize(c?.countryName?.toLowerCase().replace(' ', '').normalize('NFD').replace(/[\u0300-\u036f]/g, ''))?.includes(filterValue)
            || utf8ToEnglishNormalize(c?.countryCode?.toLowerCase().replace(' ', '').normalize('NFD').replace(/[\u0300-\u036f]/g, ''))?.includes(filterValue))
        || (utf8ToEnglishNormalize(company.sector?.industryCode?.toLowerCase().replace(' ', ''))?.includes(filterValue))
        || (utf8ToEnglishNormalize(company.sector?.industryName?.toLowerCase().replace(' ', ''))?.includes(filterValue))
        || (utf8ToEnglishNormalize(company.sector?.sectorCode?.toLowerCase().replace(' ', ''))?.includes(filterValue))
        || (utf8ToEnglishNormalize(company.sector?.sectorName?.toLowerCase().replace(' ', ''))?.includes(filterValue))
        || company.tags.find(t => utf8ToEnglishNormalize(t?.toLowerCase().replace(' ', '').normalize('NFD').replace(/[\u0300-\u036f]/g, ''))?.includes(filterValue))
        || (company.companyNameInReports || []).find(t => utf8ToEnglishNormalize(t?.toLowerCase().replace(' ', '').normalize('NFD').replace(/[\u0300-\u036f]/g, ''))?.includes(filterValue));
    return result
}

export function companyDTtitle(c: CompanyDT): string {
    if (!c) return ""
    return [c.name].concat(c.companyNameInReports).concat(c.sector ? [c.sector.sectorName.toLowerCase()] : []).concat(c.country.map(c => c.countryCode)).concat(c.tags).join(' | ')
}

export function testCompanyUsingString(company: Company, query: string, processedQuery = false) {
    if (!company) return false
    const filterValue = processedQuery ? query : utf8ToEnglishNormalize(query.trim().toLowerCase().replace(' ', '').normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
    const result =
        utf8ToEnglishNormalize(company.name.toLowerCase().replace(' ', ''))?.includes(filterValue)
        || utf8ToEnglishNormalize(company?.country?.toLowerCase().replace(' ', '').normalize('NFD').replace(/[\u0300-\u036f]/g, ''))?.includes(filterValue)
        || utf8ToEnglishNormalize(company.sector?.toLowerCase().replace(' ', '').normalize('NFD').replace(/[\u0300-\u036f]/g, ''))?.includes(filterValue)
        || company.tags.find(t => utf8ToEnglishNormalize(t?.toLowerCase().replace(' ', '').normalize('NFD').replace(/[\u0300-\u036f]/g, ''))?.includes(filterValue))
        || (company.companyNameInReports || []).find(t => utf8ToEnglishNormalize(t?.toLowerCase().replace(' ', '').normalize('NFD').replace(/[\u0300-\u036f]/g, ''))?.includes(filterValue));
    return result
}

export function companyTitle(c: Company): string {
    if (!c) return ""
    return [c.name].concat(c.companyNameInReports).concat(c.sector ? [c.sector.toLowerCase()] : []).concat(c.country ? [c.country.toLowerCase()] : []).concat(c.tags).join(' | ')
}


export function getLogo(n: number): string {
    return environment.server + 'api/issuer/' + (n ?? 0) + "/logo"
}


@Pipe({
    name: 'protectedLink',
})
export class ProtectedLinkPipe implements PipeTransform {
    constructor(private httpClient: HttpClient, private _sanitizer: DomSanitizer) {

    }


    transform(url: string): Observable<string> {
        return this.httpClient.get(url, { responseType: 'blob' }).pipe(
            switchMap((blob: Blob) => {
                return from(new Promise((resolve, _) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.readAsDataURL(blob);
                }))
            }),
            map((b: string) => {
                //console.log(b)
                return this._sanitizer.bypassSecurityTrustResourceUrl(b)
            }),
            catchError((er) => {
                return of();
            })) as Observable<string>
    }
}

@NgModule({
    imports: [
        HttpClientModule
    ],
    declarations: [
        ProtectedLinkPipe
    ],
    exports: [
        ProtectedLinkPipe
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ]
})
export class CoreCompanyUtilsPipesModule { }
