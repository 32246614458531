import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, filter, finalize, map, take, tap } from 'rxjs/operators';
import { controlAccessPopUp } from '../ui-notification/ControlAccessDialog/controlaccess.component';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'app/core/user/user.service';
import { openConfirmDialog } from '../ui-notification/ConfirmDialog/confirm-dialog.component';
import { AuthService } from '../../core/auth/auth.service';

const processCount: BehaviorSubject<number> = new BehaviorSubject<number>(0)
export const isLoading: Observable<boolean> = processCount.pipe(map(c => c > 0))

export function addProcessing(): void {
    processCount.next(processCount.value + 1)
}

export function popProcessing(): void {
    if (processCount.value < 0)
        processCount.next(0)
    else {
        processCount.next(processCount.value - 1)
    }
}

@Injectable({
    providedIn: 'root'
})
export class LoaderHttpInterceptor implements HttpInterceptor {
    private accessPopupShown = false;

    constructor(private dialog: MatDialog,
        private _userService: UserService, private _authService: AuthService,) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        addProcessing();
        return next.handle(request).pipe(
            filter((event) => event instanceof HttpResponse),
            //delay(2000),
            tap(
                (res) => {
                    console.warn('Success from ' + request.url);
                },
                (res) => {
                    console.warn('Failure from ' + request.url, res.status);

                    const httpErr = res as HttpErrorResponse
                    if (res.status == 403 && httpErr?.error != "Email not verified" && !this.accessPopupShown) {
                        this._userService.user$.pipe(take(1)).subscribe(u => {
                            this.accessPopupShown = true; // Set the bool to true
                            const neededAccountLicence: string = u.accessLevel == 0 ? "Premium" : "Premium +"
                            const dialogRef = controlAccessPopUp(
                                this.dialog,
                                neededAccountLicence,
                                'Subscribe to ' + neededAccountLicence
                            );
                            dialogRef.afterClosed().subscribe(() => {
                                this.accessPopupShown = false; // when the popup is closed
                            });
                            console.log("User ", u)
                        })
                    }
                }
            ),
            finalize(() => {
                popProcessing();
            })
        );
    }
}



