import { Component, Inject } from "@angular/core";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmDialog } from "../ConfirmDialog/confirm-dialog.component";
import { Router } from "@angular/router";


@Component({
    selector: 'confirm-dialog',
    templateUrl: './controlaccess.component.html',
    styleUrls: ['./controlaccess.component.scss']
})
export class ControlAccessDialogComponent {
    account : string;
    constructor(public dialogRef: MatDialogRef<ConfirmDialog>, @Inject(MAT_DIALOG_DATA) public data: {confirmText: string , account : string}, private router: Router) {}

    onOkClick(): void {
        window.open('https://meetings.hubspot.com/alexandre-prat-fourcade/clone', '_blank');
    }
}

export function controlAccessPopUp (dialog: MatDialog, account : string, confirmText: string) {
    return dialog.open(ControlAccessDialogComponent, {
        disableClose: false,
        autoFocus: false,
        width: "950px",
        height: "512px",
        backdropClass: ["backdrop-blur"],
        data: {confirmText: confirmText, account : account}
    })
}



