import { Route } from '@angular/router';
import { InitialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { AdminGuard } from './core/auth/guards/admin.guard';
import { LicenseGuardGenerator } from './core/auth/guards/license.guard';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: '/company' },

    // Redirect signed in user to the '/company'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'company' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            //{path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) }
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) }
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule) },
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: 'company', loadChildren: () => import('app/modules/admin/company/company.module').then(m => m.CompanyModule) },
            { path: 'singlecompany/:id', loadChildren: () => import('app/modules/admin/singlecompany/singlecompany.module').then(m => m.SingleCompanyModule) },
            { path: 'statements', loadChildren: () => import('app/modules/admin/statements/statements.module').then(m => m.StatementsModule) },
            { path: 'businessmodel', loadChildren: () => import('app/modules/admin/businessmodel/businessmodel.module').then(m => m.BusinessModelModule) },
            { path: 'debt', loadChildren: () => import('app/modules/admin/debt/debt.module').then(m => m.DebtModule) },
            { path: 'analytics', loadChildren: () => import('app/modules/admin/analytics/analytics.module').then(m => m.AnalyticsModule) },
            { path: 'extrafinancial', loadChildren: () => import('app/modules/admin/extrafinancial/extrafinancial.module').then(m => m.ExtraFinancialModule) },
            { path: 'datadeepdive', loadChildren: () => import('app/modules/admin/datadeepdive/datadeepdive.module').then(m => m.DataDeepdiveModule) },
            { path: 'collected', loadChildren: () => import('app/modules/admin/collected/collected.module').then(m => m.CollectedModule) },
            { path: 'entities', loadChildren: () => import('app/modules/admin/entities/entities.module').then(m => m.EntitiesModule) },
            { path: 'entity/:id', loadChildren: () => import('app/modules/admin/entity/entity.module').then(m => m.EntityModule) },
            { path: 'groups', loadChildren: () => import('app/modules/admin/entity-group/entity-group.module').then(m => m.EntityGroupModule) },
            { path: 'metrics', loadChildren: () => import('app/modules/admin/metrics/metrics.module').then(m => m.MetricsModule) },
            { path: 'preferences', loadChildren: () => import('app/modules/admin/preferences/preferences.module').then(m => m.PreferencesModule) },
            { path: 'query', loadChildren: () => import('app/modules/admin/query/query.module').then(m => m.QueryModule) },
            { path: 'reports', loadChildren: () => import('app/modules/admin/reports/reports.module').then(m => m.ReportsModule) },
            { path: 'graph', loadChildren: () => import('app/modules/admin/graph/graph.module').then(m => m.GraphModule) },
            { path: 'ppa', loadChildren: () => import('app/modules/admin/ppa/ppa.module').then(m => m.PpaModule) },
            { path: 'comparison', loadChildren: () => import('app/modules/admin/comparison/comparison.module').then(m => m.ComparisonModule) },

            { path: 'benchmark-view', loadChildren: () => import('app/modules/admin/benchmark-view/benchmark-view.module').then(m => m.BenchmarkViewModule) },
            { path: 'notes-view', loadChildren: () => import('app/modules/admin/notes-view/notes-view.module').then(m => m.NotesViewModule) },
            { path: 'notes-mapping', loadChildren: () => import('app/modules/admin/notes-mapping/notes-mapping.module').then(m => m.NotesMappingModule) },
            { path: 'human-view', loadChildren: () => import('app/modules/admin/human-view/human-view.module').then(m => m.HumanViewModule) },
            { path: 'mapping-view', loadChildren: () => import('app/modules/admin/mapping-view/mapping-view.module').then(m => m.MappingViewModule) },
            { path: 'example', loadChildren: () => import('app/modules/admin/example/example.module').then(m => m.ExampleModule) },
            { path: 'comparison-view', loadChildren: () => import('app/modules/admin/companiesComparison/companiesComparison.module').then(m => m.CompaniesComparisonModule) },
            { path: 'calculation-analyzer', loadChildren: () => import('app/modules/admin/calculation-analyzer/calculation-analyzer.module').then(m => m.CalculationAnalyzerModule) },
            { path: 'side-by-side', loadChildren: () => import('app/modules/admin/side-by-side/side-by-side.module').then(m => m.SideBySideModule) },
            { path: 'favorites', loadChildren: () => import('app/modules/admin/favorites/favorites.module').then(m => m.FavoritesModule) },
            { path: 'screenings', loadChildren: () => import('app/modules/admin/screening/screening.module').then(m => m.ScreeningModule) },
            { path: 'metric-lists', loadChildren: () => import('app/modules/admin/metric-lists/metric-list.module').then(m => m.MetricListModule) },
            { path: 'company-lists', loadChildren: () => import('app/modules/admin/company-lists/company-list.module').then(m => m.CompanyListModule) },
            { path: 'model', loadChildren: () => import('app/modules/admin/model/model.module').then(m => m.ModelModule) },
            { path: 'calculation-view/:id', loadChildren: () => import('app/modules/admin/calculation-view/calculation-view.module').then(m => m.CalculationViewModule) },
            { path: 'comparison-home', loadChildren: () => import('app/modules/admin/comparison-home/comparison-home.module').then(m => m.ComparisonHomeModule) },
            { path: 'static-company-list/:id', loadChildren: () => import('app/modules/admin/static-company-list/static-company-list.module').then(m => m.StaticCompanyListModule) },
            { path: 'dynamic-company-list/:id', loadChildren: () => import('app/modules/admin/dynamic-company-list/dynamic-company-list.module').then(m => m.DynamicCompanyListModule) },
            { path: 'release-notes', loadChildren: () => import('app/modules/admin/release-notes/release-notes.module').then(m => m.ReleaseNotesModule) },
            { path: 'download-protected-link', loadChildren: () => import('app/modules/admin/access-protected-link/access-protected-link.module').then(m => m.AccessProtectedLinkModule) },
            { path: 'access-report-fragment', loadChildren: () => import('app/modules/admin/access-report-fragment/access-report-fragment.module').then(m => m.AccessReportFragmentModule) },
            { path: 'disclosure', loadChildren: () => import('app/modules/admin/disclosure/disclosure.module').then(m => m.DisclosureModule) },
        ]
    },

    {
        path: '',
        canActivate: [AuthGuard, AdminGuard],
        canActivateChild: [AuthGuard, AdminGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: 'admin', loadChildren: () => import('app/modules/admin/admin/admin.module').then(m => m.AdminModule) },
            { path: 'admin-users', loadChildren: () => import('app/modules/admin/admin/admin-users/admin-users.module').then(m => m.AdminUsersModule) },
            { path: 'admin-index', loadChildren: () => import('app/modules/admin/admin/admin-index/admin-index.module').then(m => m.AdminIndexModule) },
            { path: 'admin-data-validation', loadChildren: () => import('app/modules/admin/admin/admin-data-validation/admin-data-validation.module').then(m => m.AdminDataValidationModule) },
            { path: 'admin-upload-report', loadChildren: () => import('app/modules/admin/admin/admin-upload-report/admin-upload-report.module').then(m => m.AdminUploadReportModule) }
        ]
    },
    {
        path: '',
        canActivate: [AuthGuard, LicenseGuardGenerator(['benchmark'], 1)],
        canActivateChild: [AuthGuard, LicenseGuardGenerator(['benchmark'], 1)],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: 'benchmark', loadChildren: () => import('app/modules/admin/benchmark/benchmark.module').then(m => m.BenchmarkModule) },
        ]
    },
    { path: '**', redirectTo: '/company' }
];
