/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'quality',
        title: '',
        subtitle: '',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            /*{
                id: 'home',
                title: 'Home',
                type: 'collapsable',
                icon: 'heroicons_outline:home',
                link: '/home'
            },*/
            {
                id: 'DAP-nav-company',
                title: 'Company',
                type: 'collapsable',
                icon: 'heroicons_outline:globe',
                link: '/company',
                children: [
                    {
                        id: 'DAP-nav-company-home',
                        title: 'Overview',
                        type : 'basic',
                        icon: 'heroicons_outline:identification',
                        link: '/singlecompany',
                        temporary: true,
                    },
                    {
                        id: 'DAP-nav-financial-statements',
                        title: 'Financial statements',
                        type : 'basic',
                        icon: 'heroicons_outline:document-text',
                        link: '/statements',
                        temporary: true,
                    },
                    /*{
                        id   : 'businessmodel',
                        title: 'Business model',
                        type : 'basic',
                        icon: 'heroicons_outline:briefcase',
                        link : '/businessmodel'
                    },
                    {
                        id   : 'debt',
                        title: 'Debt',
                        type : 'basic',
                        icon: 'heroicons_outline:credit-card',
                        link : '/debt'
                    },
                    {
                        id   : 'analytics',
                        title: 'Analytics',
                        type : 'basic',
                        icon: 'heroicons_outline:chart-bar',
                        link : '/analytics'
                    },
                    {
                        id   : 'extrafinancial',
                        title: 'Extra-financial',
                        type : 'basic',
                        icon: 'heroicons_outline:sun',
                        link : '/extrafinancial'
                    },
                    {
                        id   : 'datadeepdive',
                        title: 'Data deep-dive',
                        type : 'basic',
                        icon: 'heroicons_outline:search-circle',
                        link : '/datadeepdive'
                    }*/
                ]
            },
            //{
            //    id: 'peers',
            //    title: 'Peers',
            //    type: 'collapsable',
            //    icon: 'heroicons_outline:user-group',
            //    link: '/entities',

            //},
            {
                id: 'DAP-nav-screening',
                title: 'Screening',
                type: 'collapsable',
                icon: 'heroicons_outline:eye', //calculate',
                link: '/entities'
            },
            {
                id: 'DAP-nav-comparison',
                title: 'Comparison',
                type: 'collapsable',
                icon: 'mat_outline:compare',
                link: '/comparison-home'
            },
            // {
            //     id: 'comparison-home',
            //     title: 'comparison-home',
            //     type: 'collapsable',
            //     icon: 'heroicons_outline:calculator',
            //     link: '/comparison-home'
            // },
            {
                id: 'DAP-nav-benchmark',
                title: 'Benchmark',
                type: 'collapsable',
                icon: 'heroicons_outline:view-boards',
                link: '/benchmark'
            },
            {
                id: 'DAP-nav-disclosure',
                title: 'Disclosures',
                type: 'collapsable',
                icon: 'mat_outline:perm_device_information',
                link: '/disclosure'
            },
            {
                id: 'DAP-nav-model',
                title: 'Models',
                type: 'collapsable',
                icon: 'mat_outline:model_training',
                link: '/model'
            },
        ]
    },
    {
        id: 'bottoms',
        title: 'My settings',
        subtitle: 'My settings',
        type: 'spacer',
        icon: 'heroicons_outline:minus'
    },
    {
        id: 'bottom',
        title: 'My settings',
        subtitle: 'My settings',
        type: 'group',
        icon: 'heroicons_outline:minus',
        children: [
            {
                id: 'DAP-nav-favorites',
                title: 'Favorites',
                type: 'collapsable',
                icon: 'heroicons_outline:bookmark',
                link: '/favorites'
            },
            {
                id: 'DAP-nav-company-list',
                title: 'Company lists',
                type: 'collapsable',
                icon: 'list',
                link: '/company-lists'
            },
            {
                id: 'DAP-nav-metric-list',
                title: 'Metric lists',
                type: 'collapsable',
                icon: 'library_books',
                link: '/metric-lists'
            },
            {
                id: 'DAP-nav-calculation',
                title: 'Calculations',
                type: 'collapsable',
                icon: ['fas', 'calculator'],
                fontAwsome: true,
                link: '/notfound'
            },
            /*{
                id: 'lists',
                title: 'Lists & Portfolios',
                type: 'collapsable',
                icon: 'heroicons_outline:view-list',
                link: '/lists'
            },
            {
                id: 'indicators',
                title: 'Indicators',
                type: 'collapsable',
                icon: 'heroicons_outline:calculator',
                link: '/indicators'
            }*/
        ]
    }
    /*{
        id: 'dashboards',
        title: '',
        subtitle: '',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'collected',
                title: 'Dashboard',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-copy',
                link: '/collected'
            },
            {
                id: 'query',
                title: 'Query',
                type: 'basic',
                icon: 'heroicons_outline:database',
                link: '/query'
            },
            {
                id: 'entities',
                title: 'Compare',
                type: 'basic',
                icon: 'heroicons_outline:scale',
                link: '/entities'
            },
            {
                id: 'metrics',
                title: 'Export',
                type: 'basic',
                icon: 'heroicons_outline:cloud-download',
                link: '/metrics'
            },
            {
                id: 'metrics',
                title: 'Benchmark',
                type: 'basic',
                icon: 'heroicons_outline:globe-alt',
                link: '/benchmark'
            }
            
        ]
    },
    {
        id: 'personal',
        title: 'Personal',
        subtitle: 'Your own data perimeters',
        type: 'group',
        icon: 'heroicons_outline:finger-print',
        children: [
            {
                id: 'entity-groups',
                title: 'Entity groups',
                type: 'basic',
                icon: 'heroicons_outline:office-building',
                link: '/entitygroups'
            },
            {
                id: 'metric-derivations',
                title: 'Indicators',
                type: 'basic',
                icon: 'heroicons_outline:variable',
                link: '/metricderivations'
            },
            {
                id: 'settings',
                title: 'General settings',
                type: 'basic',
                icon: 'heroicons_outline:adjustments',
                link: '/settings'
            }
        ]
    },
    {
        id: 'other',
        title: 'Services',
        subtitle: '',
        type: 'group',
        icon: 'heroicons_outline:information-circle',
        children: [
            {
                id: 'documentation',
                title: 'Documentation',
                type: 'basic',
                icon: 'heroicons_outline:light-bulb',
                link: '/documentation'
            },
            {
                id: 'support',
                title: 'Help center',
                type: 'basic',
                icon: 'heroicons_outline:support',
                link: '/support'
            }
        ]
    }*/
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'collected',
        title: 'Collected',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/collected'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'collected',
        title: 'Collected',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/collected'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'collected',
        title: 'Collected',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/collected'
    }
];
