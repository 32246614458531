import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { SectorService } from "../../sector/sector.service";
import { Sector } from "../../sector/sector.types";
import { CompanyRawService } from "../company-raw/company-raw.service";
import { Company } from "../company-raw/company-raw.types";
import { CompanyDT } from "./company-dt.types";
import { countryCodeToName } from "./country.type";


@Injectable({
    providedIn: 'root'
})
export class CompanyDTService {
    private _companies: BehaviorSubject<CompanyDT[] | null> = new BehaviorSubject(null);

    constructor(private sectorService: SectorService, private companyService: CompanyRawService) {
        combineLatest([this.sectorService.sectors$, this.companyService.companies$])
            .subscribe(([sectors, comps]) => {
                this.processSectorsAndCompanies(sectors, comps)
            })
    }

    get companies$(): Observable<CompanyDT[]> {
        return this._companies.asObservable();
    }

    getCompanies(): Observable<CompanyDT[]> {
        if (this._companies.value == null) {
            return forkJoin(
                {
                    comps: this.companyService.getCompanies(),
                    sects: this.sectorService.getSectors()
                }
            ).pipe(switchMap(result => {
                this.processSectorsAndCompanies(result.sects, result.comps)
                return of(this._companies.value)
            }))
        }
        else {
            return of(this._companies.value);
        }

    }

    private processSectorsAndCompanies(sectors: Sector[], comps: Company[]) {
        if (sectors && comps) {
            this._companies.next(comps.map(c => {
                const sector = sectors.find(s => s.sectorCode == c.sector)
                return {
                    ...c,
                    country: (c.country ? c.country.split('-').reduce(
                        (acc, cs) => {
                            if (!countryCodeToName[cs]) {
                                /*console.error("Could not find the country " + cs)
                                console.error(c)*/
                            }
                            else {
                                acc.push({ countryCode: cs, countryName: countryCodeToName[cs] })
                            }
                            return acc
                        },
                        []
                    )
                        : []),
                    sector: sector,
                }
            }))
        }
    }
}


@Injectable({
    providedIn: 'root'
})
export class CompanyDTResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _companyService: CompanyDTService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        console.log("resolving companyDTs");
        return this._companyService.getCompanies()
    }
}
