import { Benchmark } from "../../core/benchmark/benchmark.types";

export const benchmarkData: Benchmark[] = [
    {
        title: "Metric frequencies",
        comment: `Statistics on the use of metrics, their components and the subtotals they contribute to.`,
        date: new Date("12.09.2022"),
        link: "benchmark-view"
    },

    {
        title: "Note contents",
        comment: `Search for notes discussing specific topics.`,
        date: new Date("05.14.2023"),
        link: "notes-view",
        id: "DAP-benchmark-NoteContent"
    },

    {
        title: "Mappings",
        comment: `Find what metrics are associated with a specific human-readable label.`,
        date: new Date("07.17.2023"),
        link: "mapping-view"
    },

    {
        title: "Human labels",
        comment: `Find what human-readable labels are displayed for a specific metric.`,
        date: new Date("07.18.2023"),
        link: "human-view"
    },

    {
        title: "IFRS notes mapping",
        comment: `Find suggestions of how to tag text block contents.`,
        date: new Date("07.24.2024"),
        link: "notes-mapping"
    }

];
