/* eslint-disable */
import * as moment from 'moment';

export const collected = {
    statistics: {
        series: {
            'total': [
                {
                    name: 'Visitors',
                    data: [...Array(30).keys()].map(i => {
                        let date: Date = new Date();
                        date.setDate(date.getDate() - i);

                        let y: number = 0;
                        if (i % 7 == 0 || i % 7 == 1) {
                            y = 0;
                        }
                        else {
                            y = Math.floor(300 * Math.random() * Math.random() * Math.random())
                        }
                        return { x: date, y: y }
                    })
                }
            ],
            'financial': [
                {
                    name: 'Visitors',
                    data: [...Array(30).keys()].map(i => {
                        let date: Date = new Date();
                        date.setDate(date.getDate() - i);

                        let y: number = 0;
                        if (i % 7 == 0 || i % 7 == 1) {
                            y = 0;
                        }
                        else {
                            y = Math.floor(300 * Math.random() * Math.random() * Math.random())
                        }
                        return { x: date, y: y }
                    })
                }
            ]
        }
    },
    documents: {
        "2021-10-21": [
            {
                type: 'URD',
                entity: 'Métropole Télévision',
                description: 'Annual report lorem ipsum and something and a couple of other mentions',
                verified: true,
                start: "2020-01-01",
                end: "2020-12-31",
                n_periods: 2,
                n_concepts: 219,
                n_facts: 839,
                id: 0
            },
            {
                type: '10-K',
                entity: 'Microsoft, Inc.',
                description: 'Annual report lorem ipsum and something and a couple of other mentions',
                start: "2020-01-01",
                end: "2020-12-31",
                n_periods: 2,
                n_concepts: 219,
                n_facts: 839,
                id: 0
            },
            {
                type: 'URD',
                entity: 'Métropole Télévision',
                description: 'Annual report lorem ipsum and something and a couple of other mentions',
                verified: true,
                start: "2020-01-01",
                end: "2020-12-31",
                n_periods: 2,
                n_concepts: 219,
                n_facts: 839,
                id: 0
            }
        ],
        "2021-10-22": [
            {
                type: '10-K',
                entity: 'Microsoft, Inc.',
                description: 'Annual report lorem ipsum and something and a couple of other mentions',
                start: "2020-01-01",
                end: "2020-12-31",
                n_periods: 2,
                n_concepts: 219,
                n_facts: 839,
                id: 0
            },
            {
                type: 'URD',
                entity: 'Métropole Télévision',
                description: 'Annual report lorem ipsum and something and a couple of other mentions',
                verified: true,
                start: "2020-01-01",
                end: "2020-12-31",
                n_periods: 2,
                n_concepts: 219,
                n_facts: 839,
                id: 0
            },
            {
                entity: 'Continental',
                type: 'Extra-financial',
                description: 'Extra-financial annual report for the year 2020',
                start: "2020-01-01",
                end: "2020-12-31",
                n_periods: 2,
                n_concepts: 219,
                n_facts: 839,
                id: 0
            },
            {
                type: 'URD',
                entity: 'Métropole Télévision',
                description: 'Annual report lorem ipsum and something and a couple of other mentions',
                verified: true,
                start: "2020-01-01",
                end: "2020-12-31",
                n_periods: 2,
                n_concepts: 219,
                n_facts: 839,
                id: 0
            },
            {
                entity: 'Continental',
                type: 'Extra-financial',
                description: 'Extra-financial annual report for the year 2020',
                start: "2020-01-01",
                end: "2020-12-31",
                n_periods: 2,
                n_concepts: 219,
                n_facts: 839,
                id: 0
            },
            {
                type: 'URD',
                entity: 'Métropole Télévision',
                description: 'Annual report lorem ipsum and something and a couple of other mentions',
                verified: true,
                start: "2020-01-01",
                end: "2020-12-31",
                n_periods: 2,
                n_concepts: 219,
                n_facts: 839,
                id: 0
            },
            {
                entity: 'Continental',
                type: 'Extra-financial',
                description: 'Extra-financial annual report for the year 2020',
                start: "2020-01-01",
                end: "2020-12-31",
                n_periods: 2,
                n_concepts: 219,
                n_facts: 839,
                id: 0
            },
            {
                type: 'URD',
                entity: 'Métropole Télévision',
                description: 'Annual report lorem ipsum and something and a couple of other mentions',
                verified: true,
                start: "2020-01-01",
                end: "2020-12-31",
                n_periods: 2,
                n_concepts: 219,
                n_facts: 839,
                id: 0
            },
            {
                entity: 'Continental',
                type: 'Extra-financial',
                description: 'Extra-financial annual report for the year 2020',
                start: "2020-01-01",
                end: "2020-12-31",
                n_periods: 2,
                n_concepts: 219,
                n_facts: 839,
                id: 0
            },
            {
                type: 'URD',
                entity: 'Métropole Télévision',
                description: 'Annual report lorem ipsum and something and a couple of other mentions',
                verified: true,
                start: "2020-01-01",
                end: "2020-12-31",
                n_periods: 2,
                n_concepts: 219,
                n_facts: 839,
                id: 0
            },
            {
                entity: 'Continental',
                type: 'Extra-financial',
                description: 'Extra-financial annual report for the year 2020',
                start: "2020-01-01",
                end: "2020-12-31",
                n_periods: 2,
                n_concepts: 219,
                n_facts: 839,
                id: 0
            }

        ]
    }
};
