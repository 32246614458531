import { RouterLink, Router, ActivatedRoute } from '@angular/router';
import { Directive, ContentChildren, QueryList, ElementRef, Renderer2, ChangeDetectorRef, Input } from '@angular/core';

@Directive({
    selector: '[adaptiveRouterLink]'
})
export class AdaptiveRouterLinkDirective extends RouterLink {
    @Input() set adaptiveRouterLink(link:string[] | string){
        this.routerLink = link;
        //this.skipLocationChange = window.location.href.indexOf("_host_Info=") != -1;
    }

    constructor(router: Router, route: ActivatedRoute, renderer: Renderer2, el: ElementRef){
        super(router, route, null, renderer, el);
    }
}