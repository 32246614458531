import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError, concat } from 'rxjs';
import { map, switchMap, tap, reduce, catchError, last } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { EntitiesGroupService } from '../entity-group.service';
import { ViewedCompany } from './most-viewed-company.types';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class MostViewedCompanyService {
    public mostViewedCompanies: BehaviorSubject<ViewedCompany[] | null> = new BehaviorSubject(null);
    
    constructor(protected _httpClient: HttpClient, private _entitiesGroupService: EntitiesGroupService) {
       
    }

    get mostviewedcompanies$(): Observable<ViewedCompany[]> {
        return this.mostViewedCompanies.asObservable();
    }

    getMostViewedCompanies(): Observable<ViewedCompany[]> {
        if (this.mostViewedCompanies.value == null) {
            return this._httpClient.get<ViewedCompany[]>(environment.server + 'api/issuer/issuervisitedlist').pipe(
                map((response: ViewedCompany[]) => {
                    console.log("Response from: " + environment.server + 'api/issuer/issuervisitedlist');
                    this.mostViewedCompanies.next(response);
                    return response;
                })
            );
        }
        else {
            return of(this.mostViewedCompanies.value);
        }

    }

}

@Injectable({
    providedIn: 'root'
})
export class MostViewedCompanyResolver implements Resolve<ViewedCompany[]>
{
    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _companyService: MostViewedCompanyService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ViewedCompany[]> {
        console.log("resolving most visited");
        return this._companyService.getMostViewedCompanies();
    }
}


