import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageDialog } from '../MessageDialog/message-dialog.component';

@Component({
    selector: 'spinner-dialog',
    templateUrl: './spinner-dialog.component.html',
    styleUrls: ['./spinner-dialog.component.css']
})
export class SpinnerDialogComponent implements OnInit, OnDestroy {

    content: string;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(public dialogRef: MatDialogRef<MessageDialog>, @Inject(MAT_DIALOG_DATA) public data: Subject<string>) {
        data.pipe(takeUntil(this._unsubscribeAll)).subscribe(
            txt => this.content = txt,
            err => console.log(err),
            () => dialogRef.close()
        );
    }

    ngOnInit() {

    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}

export function openSpinnerDialog(dialog: MatDialog, data: BehaviorSubject<string>): MatDialogRef<SpinnerDialogComponent> {
    return dialog.open(SpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
        data: data
    });
}
