import { Injectable } from '@angular/core';
import { assign, cloneDeep } from 'lodash-es';
import { FuseMockApiService, FuseMockApiUtils } from '@fuse/lib/mock-api';
import { querySections as querySectionsData } from 'app/mock-api/common/querycart/data';

@Injectable({
    providedIn: 'root'
})
export class QueryCartMockApi
{
    private _querySections: any = querySectionsData;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Messages - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/common/querysections')
            .reply(() => [200, cloneDeep(this._querySections)]);

        // -----------------------------------------------------------------------------------------------------
        // @ Mark all as read - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/common/querysections/mark-all-as-read')
            .reply(() => {

                // Go through all querysections
                this._querySections.forEach((item: any, index: number, querysections: any[]) => {

                    // Mark it as read
                    querysections[index].read = true;
                    querysections[index].seen = true;
                });

                // Return the response
                return [200, true];
            });
    }
}
