<div class="flex flex-col space-y-8 h-full w-full p-8">

    <!-- Header -->
    <div class="flex w-full justify-between items-center">
        <div class="text-xl">
            <h1>{{userName}}'s Favorites</h1>
        </div>
        <div class="flex space-x-4">
            <button class="btn-secondary-md" (click)="onAddFavoriteButton()">
                <fa-icon [icon]="['fas', 'pen']" class="icon-size-3 mr-2"></fa-icon>
                Add a favorite
            </button>
            <button class="btn-secondary-md" *ngIf="selection.hasValue()" (click)="onMultipleDeleteFromFavorite()">
                <fa-icon [icon]="['fas', 'pen']" class="icon-size-3 mr-2"></fa-icon>
                Remove selected companies
            </button>
        </div>
    </div>

    <!-- Body -->
    <div class="flex flex-col">

        <!-- Add companies -->
        <div class="flex flex-row w-full" *ngIf="favorites.length == 0">
            <div class="flex flex-col space-y-3 bg-background-secondary rounded-lg w-full justify-center h-full p-8">
                <div class="text-4xl text-center font-bold">Add companies to your personal list</div>
                <div class="text-center">
                    You have <span class="font-bold">no favorites yet</span>. To be able to see them here, go to <a href="/entities">peers page</a> and add them.
                </div>
            </div>
        </div>

        <!-- Table -->
        <div class="flex flex-col w-full bg-background-secondary rounded-lg shadow-lg" *ngIf="dataSource.data.length != 0">

            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="onMatSortChange($event)" class="w-full">

                <!-- Checkbox Column -->
                <ng-container matColumnDef="check-boxes" class="w-1/10">
                    <th mat-header-cell *matHeaderCellDef class="bg-table-header rounded-tl-lg">
                        <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                        [checked]="selection.hasValue()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()" class="hover:shadow-none">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row" class="">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row)" class="hover:shadow-none">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <!-- Logo Column -->
                <ng-container matColumnDef="logo" class="w-1/10">
                    <th *matHeaderCellDef mat-header-cell class="w-full text-center bg-table-header text-white">Logo</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="flex flex-row w-full">
                            <img [src]="companyLogo(element.issuerId) | protectedLink | async"
                                class="p-4 object-contain"
                                alt="Logo image">
                        </div>
                    </td>
                </ng-container>

                <!-- Names Column -->
                <ng-container matColumnDef="company-name" class="w-3/5">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-left px-4 bg-table-header text-white">Companies</th>
                    <td mat-cell *matCellDef="let element" class="px-4 text-sm">
                        <!--{{ element.name }}-->
                        <div [title]="element.name" class="nowrap truncate">
                            {{element.name}}
                        </div>
                    </td>
                </ng-container>

                <!-- Added at Column -->
                <ng-container matColumnDef="company-date" class="w-1/10">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-left px-4 bg-table-header text-white">Added at</th>
                    <td mat-cell *matCellDef="let element"  class="px-4 text-sm"> {{ element.addedAt | date: 'dd.MM.yyyy'}} at {{ element.addedAt | date: 'HH.mm'}} </td>
                </ng-container>

                <!-- Sector Column -->
                <ng-container matColumnDef="company-sector" class="w-1/10">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-left px-4 bg-table-header text-white">Sector</th>
                    <td mat-cell *matCellDef="let element"  class="px-4 text-sm"> {{ element.sector }} </td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="company-action" class="w-1/10">
                    <th mat-header-cell *matHeaderCellDef  class="text-left px-4 bg-table-header text-white rounded-tr-lg">ACTION</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="flex items-center justify-start px-4 space-x-4">
                            <button (click)="onViewCompany(element)">
                                <fa-icon [icon]="['fas', 'eye']" class="icon-size-3"></fa-icon>
                            </button>
                            <button (click)="onDeleteFromFavorite(element)">
                                <fa-icon [icon]="['fas', 'trash']" class="icon-size-3"></fa-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <mat-paginator class="flex justify-end" [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator>
        </div>

    </div>
</div>
