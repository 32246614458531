import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError, concat } from 'rxjs';
import { map, switchMap, tap, reduce, catchError, last } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Company, ViewedCompany } from 'app/modules/admin/company/company.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { EntitiesGroupService } from '../entity-group.service';
import { IssuerInGroup } from '../entity-group.types';

@Injectable({
    providedIn: 'root'
})

export class FavoriteCompanyService {
    
    constructor(private _httpClient: HttpClient, private _entitiesGroupService: EntitiesGroupService) {
        
    }

    get favorites$(): Observable<IssuerInGroup[]> {
        return this._entitiesGroupService._entitiesGroup.pipe(map(groups => {
            var save = groups.filter(group => group.name == 'Favorites');
            if (save.length == 0) {
                return [];
            }
            else {
                return save[0].group;
            }
        }));
    }

    addFavCompanies(c: IssuerInGroup): Observable<Boolean> {
        return this._entitiesGroupService.addCompanyToEntityGroup(c, "Favorites");
    }

    removeFavCompanies(c: IssuerInGroup): Observable<Boolean> {
        return this._entitiesGroupService.deleteCompanyFromEntityGroup(c, "Favorites");
    }
   
}




