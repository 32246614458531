import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';

@Injectable()
export class FuseSplashScreenService
{
    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private _document: any,
        private _router: Router
    )
    {
        this._router.events.subscribe(event => {
            switch (true) {
                case event instanceof NavigationStart: {
                    this.show()
                    break;
                }

                case event instanceof NavigationEnd:
                /*case event instanceof NavigationCancel:
                case event instanceof NavigationError:*/ {
                    setTimeout(() => {
                        this.hide();
                    }, 500);
                    break;
                }
                
                case event instanceof NavigationCancel:
                    {
                        setTimeout(() => {
                            this.hide();
                        }, 500);
                        break;
                    }
                default: {
                    break;
                }
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show the splash screen
     */
    show(): void
    {
        this._document.body.classList.remove('fuse-splash-screen-hidden');
    }

    /**
     * Hide the splash screen
     */
    hide(): void
    {
        this._document.body.classList.add('fuse-splash-screen-hidden');
    }
}
