/* eslint-disable */

export const reports = [
    {
        id: 0,
        type: '10-K',
        entity: 'Microsoft, Inc.',
        description: 'Annual report lorem ipsum and something and a couple of other mentions',
        start: "2020-01-01",
        end: "2020-12-31",
        published: "2021-02-26",
        verified: false,
        url: 'https://www.sec.gov/Archives/edgar/data/789019/000156459018019062/msft-10k_20180630.htm',
        statements: [{
            title: 'Statement of Financial Position (Balance sheet)',
            data: [
                {
                    concept: {
                        label: "Goodwill",
                        definition: "Definition for goodwill"
                    },
                    data: {
                        "2020-12-31": {
                            short: '10 000',
                            detail: '10 000 000 000 USD',
                            documentId: 'xKisAHos'
                        },
                        "2019-12-31": {
                            short: '9 000',
                            detail: '9 000 000 000 USD',
                            documentId: 'xKisAHos'
                        }
                    },
                    depth: 1
                },
                {
                    concept: {
                        label: "Cash and cash equivalents",
                        definition: "Definition for cash and cash equivalents"
                    },
                    data: {
                        "2020-12-31": {
                            short: '7 000',
                            detail: '7 000 000 000 USD',
                            documentId: 'xKisAHos'
                        },
                        "2019-12-31": {
                            short: '6 000',
                            detail: '6 000 000 000 USD',
                            documentId: 'xKisAHos'
                        }
                    },
                    depth: 1
                },
                {
                    concept: {
                        label: "Assets",
                        definition: "Definition for assets"
                    },
                    data: {
                        "2020-12-31": {
                            short: '17 000',
                            detail: '17 000 000 000 USD',
                            documentId: 'xKisAHos'
                        },
                        "2019-12-31": {
                            short: '15 000',
                            detail: '15 000 000 000 USD',
                            documentId: 'xKisAHos'
                        }
                    },
                    depth: 0
                }
            ],

        },
        {
            title: 'Income Statement',
            data: [
                {
                    concept: {
                        label: "Goodwill",
                        definition: "Definition for goodwill"
                    },
                    data: {
                        "2020-12-31": {
                            short: '10 000',
                            detail: '10 000 000 000 USD',
                            documentId: 'xKisAHos'
                        },
                        "2019-12-31": {
                            short: '9 000',
                            detail: '9 000 000 000 USD',
                            documentId: 'xKisAHos'
                        }
                    },
                    depth: 1
                },
                {
                    concept: {
                        label: "Cash and cash equivalents",
                        definition: "Definition for cash and cash equivalents"
                    },
                    data: {
                        "2020-12-31": {
                            short: '7 000',
                            detail: '7 000 000 000 USD',
                            documentId: 'xKisAHos'
                        },
                        "2019-12-31": {
                            short: '6 000',
                            detail: '6 000 000 000 USD',
                            documentId: 'xKisAHos'
                        }
                    },
                    depth: 1
                },
                {
                    concept: {
                        label: "Assets",
                        definition: "Definition for assets"
                    },
                    data: {
                        "2020-12-31": {
                            short: '17 000',
                            detail: '17 000 000 000 USD',
                            documentId: 'xKisAHos'
                        },
                        "2019-12-31": {
                            short: '15 000',
                            detail: '15 000 000 000 USD',
                            documentId: 'xKisAHos'
                        }
                    },
                    depth: 0
                }
            ],

        },
        {
            title: 'Cash Flows Statement',
            data: [
                {
                    concept: {
                        label: "Goodwill",
                        definition: "Definition for goodwill"
                    },
                    data: {
                        "2020-12-31": {
                            short: '10 000',
                            detail: '10 000 000 000 USD',
                            documentId: 'xKisAHos'
                        },
                        "2019-12-31": {
                            short: '9 000',
                            detail: '9 000 000 000 USD',
                            documentId: 'xKisAHos'
                        }
                    },
                    depth: 1
                },
                {
                    concept: {
                        label: "Cash and cash equivalents very long concept name very some sub-asset category, and maybe even longer to the point that it would take at least three lines",
                        definition: "Definition for cash and cash equivalents"
                    },
                    data: {
                        "2020-12-31": {
                            short: '7 000',
                            detail: '7 000 000 000 USD',
                            documentId: 'xKisAHos'
                        },
                        "2019-12-31": {
                            short: '6 000',
                            detail: '6 000 000 000 USD',
                            documentId: 'xKisAHos'
                        }
                    },
                    depth: 1
                },
                {
                    concept: {
                        label: "Assets",
                        definition: "Definition for assets"
                    },
                    data: {
                        "2020-12-31": {
                            short: '17 000',
                            detail: '17 000 000 000 USD',
                            documentId: 'xKisAHos'
                        },
                        "2019-12-31": {
                            short: '15 000',
                            detail: '15 000 000 000 USD',
                            documentId: 'xKisAHos'
                        }
                    },
                    depth: 0
                }
            ],

        }
        ]
    }
];