/* eslint-disable */
import * as moment from 'moment';

export const querySections = [
    {
        id         : '832276cc-c5e9-4fcc-8e23-d38e2e267bc9',
        type       : 'entities',
        icon       : 'office-building',
        title      : 'Entities',
        description: 'Entities and sectors',
        parameters : [
            {
                type: 'entity',
                label: 'Microsoft, Inc.',
                parameter: 'cik=0000012345'
            },
            {
                type: 'sector',
                label: 'US Consumer Discretionary',
                parameter: 'sector=325'
            }
        ]
    },
    {
        id         : '832276cc-c5e9-4fcc-8e23-d38e2e262bc9',
        type       : 'metrics',
        icon       : 'variable',
        title      : 'Metrics',
        description: 'Metrics and indicators',
        parameters : [
            {
                type: 'metric',
                label: 'Non-current liabilities',
                parameter: 'met=NonCurrentLiabilities'
            },
            {
                type: 'indicator',
                label: 'Free cash flow to the firm',
                parameter: 'ind=3652'
            }
        ]
    },
    {
        id         : '832276cc-c5e9-4fcc-8e23-d30e2e262bc9',
        type       : 'periods',
        icon       : 'calendar',
        title      : 'Periods',
        description: 'Periods',
        parameters : [
            {
                type: 'range',
                label: 'Year 2020',
                parameter: 'range=Y-2020'
            },
            {
                type: 'range',
                label: 'S1 2021',
                parameter: 'range=S1-2021'
            }
        ]
    }
];

export interface QuerySection
{
    id: string;
    type: string;
    icon?: string;
    title?: string;
    description?: string;
    link?: string;
    useRouter?: boolean;
    parameters: QueryParameter[];
}

export interface QueryParameter
{
    label: string;
    type: string;
    parameter: string;
}