import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from 'app/core/user/user.service';
import { map, switchMap } from 'rxjs/operators';
import { User } from 'app/core/user/user.types';
import { openConfirmDialog } from '../../../modules/ui-notification/ConfirmDialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root',
})
export class AdminGuard implements CanActivate, CanActivateChild, CanLoad {
    /**
     * Constructor
     */
    constructor(private _userService: UserService, private _router: Router, private _matDialog: MatDialog) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl);
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl);
    }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */
    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this._check('/');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @private
     */
    private _check(redirectURL: string): Observable<boolean> {
        return this._userService.user$.pipe(map(u => {
            console.log("Checking", u);
            if (u.accessLevel < 4) {
                this._router.navigate(['/'], { queryParams: { redirectURL: redirectURL } }).then(
                    (navigated: boolean) => {
                        openConfirmDialog(this._matDialog, "Forbidden", "You are NOT allowed to access the admin interface of LENS", "OK")
                    }
                );
            }
            return u.accessLevel == 4
        }))


        /*console.log("==================> in check")
        let accessLevel: number = 0;
        this._userService.user$.subscribe((user: User) => {
            accessLevel = user.accessLevel;
        });

        return this._userService.get().pipe(map(u => { return u?.accessLevel >= 2 }))*/

        /*if (accessLevel >= 2) {
            return of(true);
        } else {
            return of(false);
        }*/
    }
}
