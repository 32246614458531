import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from '../user/user.service';
import { User } from '../user/user.types';
import { access } from 'fs';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> =
        new ReplaySubject<Navigation>(1);
    _ariane: BehaviorSubject<any> = new BehaviorSubject([
        {title: "Company", link: "/company", last: false}
    ]);
    currentCompany: any = {
        id : 0,
        name : "No company selected"
    };

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
        let accessLevel: number = 0;
        this._userService.user$.subscribe((user: User) => {
            accessLevel = user.accessLevel;
        });
        return this._httpClient.get<Navigation>('api/common/navigation').pipe(
            tap(n => this._navigation.next(n))
        );
    }


    /** Access ariane */
    get ariane() { return this._ariane.value }
    set ariane(val: any) { this._ariane.next(val) }
    get ariane$() { return this._ariane.asObservable() }
}
