import { Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { ViewedCompany } from 'app/modules/admin/company/company.types';
import { Observable, Subject, of } from 'rxjs';
import { first, map, startWith, takeUntil } from 'rxjs/operators';
import { FuseScrollbarDirective } from '../../../../@fuse/directives/scrollbar';
import { CompanyDTService } from '../../../core/company/company-with-country-and-sector/company-dt.service';
import { CompanyDT } from '../../../core/company/company-with-country-and-sector/company-dt.types';
import { IssuerInGroup } from '../../../core/company/entity-group/entity-group.types';
import { FavoriteCompanyService } from '../../../core/company/entity-group/favorite-company/favorite-company.service';
import { MostViewedCompanyService } from '../../../core/company/entity-group/most-viewed-company/most-viewed-company.service';
import { RecentlyViewedCompanyService } from '../../../core/company/entity-group/recently-viewed-company/recently-viewed-company.service';
import { companyDTtitle, getLogo, testCompanyDTUsingString } from '../../../core/company/utils';
import { openErrorMessageDialog } from '../../ui-notification/MessageDialog/message-dialog.component';
import { snackAlert } from '../../ui-notification/SnackBarUtil';
import { FavoriteAddDialog } from '../favorites/favorites.component';


@Component({
    selector: 'company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit {
    companies: CompanyDT[];
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    filteredEntities: Observable<CompanyDT[]> = of([]);
    showSearchResults: boolean = false;
    displayedColumns: any = ['x1', 'x2'];
    dataSource = new MatTableDataSource<CompanyDT>([]);
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('favPaginator') myfavPaginator: MatPaginator;
    @ViewChild('qouery') queryInput: ElementRef<HTMLInputElement>;
    //queryString: string = "";
    mostViewedCompanies: ViewedCompany[];
    recentlyViewedCompanies: IssuerInGroup[];
    searchForm: FormControl = new FormControl('');
    filteredCompanies: Observable<CompanyDT[]>;

    //Fav companies
    fav_compagnies: IssuerInGroup[] = [];
    fav_compagnies_showed: IssuerInGroup[] = [];
    fav_companies_show_page: number = 0;
    fav_companies_show_size: number = 25;
    //Scroll for companies
    @ViewChildren(FuseScrollbarDirective)
    private _fuseScrollbarDirectives: QueryList<FuseScrollbarDirective>


    // Infinite scroll
    moreEl: number = 1;
    maxSearch: number = 50;

    /**
     * Constructor
     */
    constructor(
        private _navigationService: NavigationService,
        private _router: Router,
        private _companyService: CompanyDTService,
        private _mostviewedcompanyService: MostViewedCompanyService,
        private _favoriteService: FavoriteCompanyService,
        private _recentlyviewedService: RecentlyViewedCompanyService,
        private _snackBar: MatSnackBar,
        public dialog: MatDialog
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._navigationService.ariane = [
            { title: "Company", link: "/company", last: false },
            { title: "Home", link: "/company", last: true },
        ];

        this._companyService.companies$.pipe(takeUntil(this._unsubscribeAll)).subscribe(comps => {
            this.companies = comps;
        })
        this._mostviewedcompanyService.mostviewedcompanies$.pipe(takeUntil(this._unsubscribeAll)).subscribe(most => {
            this.mostViewedCompanies = most;
        });

        try {
            this._favoriteService.favorites$.pipe(takeUntil(this._unsubscribeAll)).subscribe(
                (comps: IssuerInGroup[]) => {
                    this.fav_compagnies = (comps ? comps : []).sort((a, b) => a.name.localeCompare(b.name));
                    this.fav_compagnies_showed = this.fav_compagnies.slice(this.fav_companies_show_page * this.fav_companies_show_size, (this.fav_companies_show_page + 1) * this.fav_companies_show_size);
                    if (!comps) {
                        this.openErrorDialog("Could not retrive your favorite companies")
                    }
                },
                (err) => {
                    this.fav_compagnies = [];
                    this.fav_compagnies_showed = this.fav_compagnies.slice(this.fav_companies_show_page * this.fav_companies_show_size, (this.fav_companies_show_page + 1) * this.fav_companies_show_size);
                    this.openErrorDialog(err.message)
                }
            );
        } catch (err) {
            console.log(err);
            this.openErrorDialog(err.message)
        }

        this._recentlyviewedService.recentvisitedcompanies$.pipe(takeUntil(this._unsubscribeAll)).subscribe(recent => {
            this.recentlyViewedCompanies = (recent[0]?.group || []);
            //console.log(recent)
            //console.log(this.recentlyViewedCompanies)
            this.recentlyViewedCompanies.sort((a, b) => new Date(b.addedAt).getTime() - new Date(a.addedAt).getTime())
        })

        this.searchForm = new FormControl('');

        this.filteredCompanies = this.searchForm.valueChanges.pipe(
            startWith(''),
            map((value: string) => this._filter(value)),
        );
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this._fuseScrollbarDirectives.forEach((fuseScrollbarDirective) => {
            fuseScrollbarDirective.update();
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Methods
    // -----------------------------------------------------------------------------------------------------

    filterCompanies(query) {
        let q = query.toLowerCase();
        let direct = this.companies.filter(c => c.name.toLowerCase().includes(q)).sort((a, b) => a.name < b.name ? -1 : a.name == b.name ? 0 : 1);
        let indirect = this.companies.filter(c => c.tags.some(t => t.toLowerCase().includes(q))).sort((a, b) => a.name < b.name ? -1 : a.name == b.name ? 0 : 1);
        return direct.concat(indirect);
    }

    /*filterByQuery(query) {
        //console.log(query);
        //console.log("Selected Value Redirect Now");
        this.queryString = query;
        this.filteredEntities = of(
            this.filterCompanies(query).slice(0, 20)
        );
    }*/

    onKey(event) {
        if (event.key == 'Enter') {
            this.nav();
        }
    }
    
    navigateToIssuerFromTable(id){
        //console.log("navigateToIssuerFromTable");
        this._router.navigateByUrl("/singlecompany/" + id);
    }

    navigateTo(query){
        //console.log(query);
        let qx = query.toLowerCase();
        //console.log(qx);
        let id = this.companies.filter(m => m.name.toLowerCase() === qx)[0].issuerId;
        //console.log("comanycomponent");
        this._router.navigateByUrl("/singlecompany/" + id);
    }
    
    nav(): void {
        this.showSearchResults = true;
        console.log("nav de company");
        console.log(this.searchForm.value)
        this.dataSource.data = this.filterCompanies(this.searchForm.value || '');
        setTimeout(() => this.dataSource.paginator = this.paginator, 0);
        console.log(this.dataSource);
        console.log("datasource");
    }

    favChangePage($event) {
        this.fav_companies_show_page = $event.pageIndex;
        this.fav_companies_show_size = $event.pageSize;
        this.fav_compagnies_showed = this.fav_compagnies.slice(this.fav_companies_show_page * this.fav_companies_show_size, (this.fav_companies_show_page + 1) * this.fav_companies_show_size);

    }

    openErrorDialog(message, title = "Error"): void {
        openErrorMessageDialog(this.dialog, title, message)
    }



    onAddFavoriteButton() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        const companies: CompanyDT[] = this.companies/*.map(c => {
            return {
                name: c.name,
                issuerId: c.issuerId,
                tags: c.tags.concat([c.sector?.sectorName, c.sector?.industryCode, c.sector?.industryName, c.sector?.sectorCode]),
                country: c.country.map(c => c.countryName).join('-'),
                sector: c.sector?.sectorName,
                companyNameInReports: c.companyNameInReports,
                reports: c.reports,
                groupNames: c.groupNames,
                balanceSheetSize: c.balanceSheetSize,
                balanceSheetSizeCurrency: c.balanceSheetSizeCurrency,
                incomeStatementSize: c.incomeStatementSize,
                incomeStatementSizeCurrency: c.incomeStatementSizeCurrency
            }
        });*/
        dialogConfig.data = companies
        dialogConfig.width = "80%";
        dialogConfig.height = "60%";
        dialogConfig.backdropClass = ["backdrop-blur"]
        this.dialog.open(FavoriteAddDialog, dialogConfig).afterClosed().subscribe(
            (cname) => {
                const c = this.companies.find(c => c.name == cname)
                if (c) {
                    this._favoriteService.addFavCompanies({ id: Number(c.issuerId), name: c.name }).pipe(first()).subscribe(
                        (success) => {
                            if (!success) {
                                snackAlert(this._snackBar, "Already added", true)
                            }
                            else {
                                snackAlert(this._snackBar, "Added as favorite")
                            }
                        },
                        (err) => {
                            console.log(err);
                            snackAlert(this._snackBar, "Failed to add as favorite", true)
                        }
                    )
                }
            }
        );
    }

    changeStyleTruncate($event: MouseEvent) {
        ($event.target as Element).classList.toggle("truncate")
    }

    companyLogo(comp: IssuerInGroup | CompanyDT) {
        return getLogo('id' in comp ?  comp.id : comp.issuerId)
    }

    private _filter(value: string, count = 20): CompanyDT[] {
        const result = []
        var added = 0
        for (const c of this.companies) {
            if (testCompanyDTUsingString(c, value)) {
                added++;
                result.push(c)
            }
            if (added >= count) break;
        }
        return result
    }

    companyToString(c: CompanyDT): string {
        return companyDTtitle(c)
    }

}

@Component({
    selector: 'my-error-dialog',
    template: `
<h1 mat-dialog-title class="text-4xl">{{data.title}}</h1>
<div class="h-0.5 bg-gray-300 my-2"></div>
<div mat-dialog-content class="text-lg my-2">
    {{ data.message }}
</div>
<div mat-dialog-actions class="flex mt-5">
    <div class="flex-1"></div>
	<button class="border-2 rounded-xl p-2 w-1/5 border-gray-300 hover:bg-gray-300" mat-raised-button mat-dialog-close tabindex="-1" (click)="onNoClick()" >Ok</button>
    <div class="flex-1"></div>
</div>
`,
})
export class ErrorDialog {
    constructor(public dialogRef: MatDialogRef<ErrorDialog>, @Inject(MAT_DIALOG_DATA) public data: { message: string, title: string }) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
