import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { reports as reportsData } from 'app/mock-api/reports/data';

@Injectable({
    providedIn: 'root'
})
export class ReportsMockApi {
    private _reports: any = reportsData;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Sales - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/query/reports')
            .reply(({ request }) => {

                // Get the id from the params
                const id = request.params.get('id');

                // Clone the reports
                const reports = cloneDeep(this._reports);

                // Find the report
                const report = reports.find(item => item.id === id);

                return [
                    200,
                    report
                ];
            });
    }
}