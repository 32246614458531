import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { Calculation, Valuation } from '../../core/model/model.types';
import { calculationData, valuationData } from './data';

@Injectable({
    providedIn: 'root'
})
export class ModelMockApi
{
    private calculations: Calculation[] = calculationData;
    private valuations: Valuation[] = valuationData;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        this._fuseMockApiService
            .onGet('api/model/calculations')
            .reply(() => [200, cloneDeep(this.calculations)]);

        this._fuseMockApiService
            .onGet('api/model/valuations')
            .reply(() => [200, cloneDeep(this.valuations)]);
    }
}
