import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'environments/environment';
import { AppModule } from 'app/app.module';
import { get } from 'scriptjs';

let inXls = window.location.href.indexOf("_host_Info=") != -1;

if (inXls) {
    get("https://appsforoffice.microsoft.com/lib/1/hosted/Office.js", () => {
        Office.initialize = () => {
            if (environment.production) {
                enableProdMode();
            }

            platformBrowserDynamic().bootstrapModule(AppModule)
                .catch(err => console.error(err));
        };
    })
}
else {
    if (environment.production) {
        enableProdMode();
    }

    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.error(err));
}



