import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'message-dialog',
    template: `
<div class="flex flex-col p-6" [class]="data.additionalClass"> 
    <h1 mat-dialog-title class="text-4xl">{{data.title}}</h1>
    <div class="h-0.5 bg-gray-300 my-2"></div>
    <div mat-dialog-content class="text-lg my-2">
        {{ data.message }}
    </div>
    <div mat-dialog-actions class="flex mt-5">
        <div class="flex-1"></div>
	    <button class="border-2 rounded-xl p-2 w-1/5 border-gray-300 hover:border-gray-500 hover:bg-gray-300 hover:text-black" mat-raised-button mat-dialog-close tabindex="-1" (click)="onNoClick()" >Ok</button>
        <div class="flex-1"></div>
    </div>
</div>
`,
    styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialog {
    //additionalClass ="bg-red-500 text-white"
    constructor(public dialogRef: MatDialogRef<MessageDialog>, @Inject(MAT_DIALOG_DATA) public data: { message: string, title: string, additionalClass: string }) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}

export function errorMessageConfig(title: string, message: string) {
    return {
        data: {
            message: message,
            title: title,
            additionalClass: "bg-red-500 text-white"
        }
    }
}

export function successMessageConfig(title: string, message: string) {
    return {
        data: {
            message: message,
            title: title,
            additionalClass: "bg-green-500 text-white"
        }
    }
}

export function openErrorMessageDialog(dialog: MatDialog, title: string, message: string) {
    return dialog.open(MessageDialog, errorMessageConfig(title, message))
}

export function openSuccessMessageDialog(dialog: MatDialog, title: string, message: string) {
    return dialog.open(MessageDialog, successMessageConfig(title, message))
}
