<div class="flex flex-col p-8 max-h-full">
    <div class="flex flex-row justify-end h-1/6 max-h-1/6"> <button class="h-6"><mat-icon svgIcon="close" class="hover:border-gray-500 hover:bg-gray-300 rounded-xl hover:cursor-pointer" (click)="onNoClick()"></mat-icon></button> </div>
    <div class="flex flex-row justify-center mt-5 h-4/6 max-h-4/6">
        <div class="flex flex-col justify-center h-full max-h-full" style="width: 95%;">
            <div class="flex flex-row w-full justify-center mb-5">
                <img
                src="assets/images/logo/logo.svg"
                class="w-14 h-14"
                alt="Logo image">
            </div>
            <p mat-dialog-title class="text-6xl font-bold mb-5 text-center">{{data.title}}</p>

            <div class="flex flex-row w-full justify-center mb-5" style="max-height: 80%; overflow-y: auto;">
                <p mat-dialog-content class="text-2xl my-2 text-center w-10/12" [innerHTML]="data.subtitle">
                </p>
            </div>

            <div mat-dialog-actions class="flex flex-row w-full justify-center mt-5">
                <button class="rounded-full hover:shadow-xl hover:bg-gray-200 px-3 py-2 text-gray-500 font-semibold text-base flex flex-row h-10 mx-1" (click)="onNoClick()" *ngIf="data.refuseText" >
                        <div class="flex flex-col h-full">
                            <div class="flex-1"></div>
                            <mat-icon [svgIcon]="'heroicons_outline:chevron-left'" class="h-4 w-4 min-w-4 min-h-4 text-gray-500"></mat-icon>
                            <div class="flex-1"></div>
                        </div>
                        <div style="line-height:1.71" class="ml-2">{{ data.refuseText }}</div>
                 </button>
                
                <button class="bg-red-500 rounded-full hover:shadow-xl px-3 py-2 text-white font-semibold text-base flex flex-row h-10 mx-1" (click)="onOkClick()">
                        <div style="line-height:1.71" class="ml-2">{{ data.confirmText }}</div>
                        <div class="flex flex-col h-full">
                            <div class="flex-1"></div>
                            <mat-icon [svgIcon]="'heroicons_outline:chevron-right'" class="h-4 w-4 min-w-4 min-h-4 text-white"></mat-icon>
                            <div class="flex-1"></div>
                        </div>
                 </button>
            </div>

            <div class="flex flex-row w-full justify-center mt-8" *ngIf="data.dontShowAgainButton">
                <mat-checkbox name="dont-show-again-checkbox"
                      [(ngModel)]="checked">
                    Do not show again
                </mat-checkbox>
            </div>
        </div>
    </div>
    <div class="flex flex-1 h-1/6 min-h-1/6"></div>
</div>
