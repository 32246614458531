import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError, concat } from 'rxjs';
import { map, switchMap, tap, reduce, catchError, last } from 'rxjs/operators';
import { EntitiesGroupService } from '../entity-group.service';
import { UserIssuerGroup } from '../entity-group.types';

@Injectable({
    providedIn: 'root'
})

export class RecentlyViewedCompanyService {
   
    constructor(protected _httpClient: HttpClient, private _entitiesGroupService: EntitiesGroupService) {
        
    }

    get recentvisitedcompanies$(): Observable<UserIssuerGroup[]> {
        return this._entitiesGroupService.entitiesGroup$.pipe(map(groups => {
            var save = groups.filter(group => group.name == 'Most Recent');
            return save;
        }));
    }
}


